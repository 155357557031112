// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../../framework/src/RunEngine";

import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { openNotification } from "../../../../components/src/Notification.web";
import axios from 'axios';

export const configJSON = require("../../../dashboard/src/config.js");
const baseUrl = configJSON.baseUrl;

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    setBackStep: () => void;
}
interface S {
    token: any;
    loading: any;

    linkedId: any;
    AudioVideoList: any;
    SearchInput: any;
    selectDownload: any;
    selectSubscription: any;
    selectedPlan: any;
    apiData: any
}
interface SS {
    id: any;
}

export default class SellingPreferencesController extends BlockComponent<Props, S, SS> {

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
           
            linkedId: '',
            AudioVideoList: [],
            SearchInput: '',
            selectDownload: false,
            selectSubscription: false,
            selectedPlan: 0,
            apiData: {}
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        const file = await getStorageData('uploaded_file')
        const coverImage = await getStorageData('uploaded_cover_image')
        const trackDetails = await getStorageData("uploaded_track_details")
        const linkTrack = await getStorageData("uploaded_linkedID")
        
        this.setState({ apiData: { file, coverImage, trackDetails, linkTrack}})
    }

    
      setDownloadPlan = () =>{
        this.setState({selectDownload: true, selectSubscription: false})
      }
    
       setSubscriptionPlan = () =>{
        this.setState({selectDownload: false, selectSubscription: true})

      }
    
       setPlan =(plan: any) =>{
        this.setState({selectedPlan: plan})
      }

      afterSellingPreferenceNext = () =>{
        if(!this.state.selectDownload && !this.state.selectSubscription){
            openNotification('Please Select Any Option To Upload Your Content', 'error')
        }else if ( this.state.selectSubscription && this.state.selectedPlan == 0){
            openNotification('Please Select Any Level To Lock Your Content', 'error')
        }
        else{      
            this.UploadTrackDeatils()
        }
      }


      UploadTrackDeatils = async() =>{
         this.setState({loading: true})
         console.log(this.state.apiData, 'track details data')
         const uploadTypeUrl = window.location.href.split('/')[4]

         const formData: any = new FormData();
         if(uploadTypeUrl == 'video'){
            formData.append('data[video_file]', this.state.apiData?.file);
         }else{
            formData.append('data[track_file]', this.state.apiData?.file);
         }
         formData.append('data[art_work]', this.state.apiData?.coverImage);
         if(this.state.apiData?.linkTrack != null){
           formData.append('data[linked_track_id]', this.state.apiData?.linkTrack)
         }

         formData.append('data[title]', this.state.apiData?.trackDetails.title)
         formData.append('data[upload_type_id]', this.state.apiData?.trackDetails.uploadType)
         formData.append('data[status]', this.state.apiData?.trackDetails.uploadStatus)
         if(this.state.apiData?.trackDetails.releaseDate != null){
          const dates = this.state.apiData?.trackDetails.releaseDate.split('/')
          const releaseDate = `${dates[2]}-${dates[0]}-${dates[1]}`
          formData.append('data[release_date]', releaseDate)
         }
         if(this.state.apiData?.trackDetails.releaseTime != null){
           const times = this.state.apiData?.trackDetails.releaseTime.split(':')
           const releaseTime =  `${times[0]}:${times[1]}:00`
           formData.append('data[release_time]', releaseTime)
         }

        

        if(this.state.apiData?.trackDetails.key != null){          
          formData.append('data[key_id]', this.state.apiData?.trackDetails.key)
        }

        if(this.state.apiData?.trackDetails.tags.length > 0){          
          formData.append('data[genres]', this.state.apiData?.trackDetails.tags)
        }

        
         if(this.state.selectedPlan){
            formData.append('data[level]', `level_${this.state.selectedPlan}`)
         }

         
        

         const headers = {
            "Content-Type": "application/json",
            'token': this.state.token
          };

          await axios.post(`${ baseUrl + '/bx_block_audiomusic/add_track_file'}`,formData,{ headers }

        )
        .then((response: any) => {
            this.setState({loading: false})
            setStorageData('uploaded_trackId', response?.data?.data?.data[0].id)
            console.log(response?.data, 'track details saved in DB')
            openNotification('Upload Successfull' , 'success')
            this.props.setActiveStep();
          }).catch((err: any) => {
            openNotification('Upload Failed' , 'error')
            this.setState({loading: false})
          });
         
      }

    
      handleAudioVideoLinkResponse = (responseJson: any) =>{
         if(responseJson?.data?.length > 0){
            this.setState({AudioVideoList: responseJson?.data})
         }else{
            this.setState({AudioVideoList: []})
         }
      }

     
}
// Customizable Area End