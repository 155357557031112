// Customizable Area Start
import React,{useEffect, useState} from 'react'
import { Grid, Button, TextField, InputAdornment} from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import axios from 'axios';
import {  Form as FormAntd,  Select as SelectAnt } from "antd";


import './index.web.css'

import { userIcon,  AddSplitsCover, ArrowIcon, coverSplitIcon, removeSplitIcon} from '../assets';
import { makeStyles } from '@material-ui/core/styles';
import { Formik,FormikErrors} from "formik";
import { openNotification } from "../../../../components/src/Notification.web";
import { getStorageData } from '../../../../framework/src/Utilities';
import { defaultProfile } from 'blocks/dashboard/src/assets';

const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;


const useStyles = makeStyles({
    root: {

      "& .MuiInputBase-root":{
        color: '#f6eeee',
        borderBottom: '3px solid #323030',
        fontWeight: 'bold'
      },
      '& ::placeholder': {
        //textOverflow: 'ellipsis !important',
        //color: 'gray',
        fontWeight: 'bold'
      },

      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderRadius: '10px',
        border: '2px solid'
        
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiInputLabel-outlined": {
        color: "white",
        fontSize: '16px',
        fontWeight: 'bold'
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white"
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      }
    },
    label: {
        color: 'rgba(0, 0, 0, 0.26)'
   }
  });

function AddSplits({setActiveStep, setBackStep, redirectSplit}: any) {

  const [loading, setLoading] = useState<any>(false);
  const [uploadType, setUploadType] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>({})
  const [splits, setSplits] = useState<any>([]);
  const [trackID, setTrackId] = useState<any>('');
  const [isSplitsCompleted, setIsSplitsCompleted] = useState<any>(false);
  const [error, setError] = useState<string | null>(null);


  const token: any = localStorage.getItem("token");

    const classes = useStyles();
    const { Option } = SelectAnt;

    
    const [form] = FormAntd.useForm();

   

    const onFinish = (values: any, actions: any) =>{
      let roleName = "";
      switch (values.role) {
        case "1":
          roleName = "Artist";
          break;
        case "2":
          roleName = "Composer";
          break;
        case "3":
          roleName = "Engineer";
          break;
        case "4":
          roleName = "Musician";
          break;
        case "5":
          roleName = "Producer";
          break;
        case "6":
          roleName = "Writer";
          break;
        default:
          break;
      }


      const splitUser = {
        account_id: selectedUser?.id,
        profile_image: selectedUser?.attributes?.avatar,
        userName: values.userName,
        role: roleName,
        split: values.split,
        user_role_id: values.role
      }
      const isUserInSplits = splits.some((split: any) => split.userName === splitUser.userName);

      if (!isUserInSplits) {

        const totalSplitPercentage = splits.reduce((total: number, split: any) => total + parseFloat(split.split), 0);
        
        if (totalSplitPercentage + parseInt(splitUser.split) > 100) {
            openNotification('Total Splits are not more than 100%', 'error')
            return;
        }else if (totalSplitPercentage + parseInt(splitUser.split) == 100){
          setIsSplitsCompleted(true)
        }else if(totalSplitPercentage + parseInt(splitUser.split) < 100){
          setIsSplitsCompleted(false)
        }
          if(values.userName != ''){
            splits.push(splitUser);
          }

          actions.setValues({
            userName: '',
            role: '',
            split: ''
          });
          setSelectedUser({})

      } else {
        openNotification('User already exists in splits.', 'error');
      }

    }

    const removeSplits= (splitToRemove: any) =>{
      const updatedSplits = splits.filter((split: any) => split.userName !== splitToRemove.userName);
      setSplits(updatedSplits);
      setIsSplitsCompleted(false)
    }

    const isEmailValid = (email: string): boolean => {
      // Regular expression for basic email validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    };
    

   const selectName = (option: any, setFieldValue: any ) =>{
    if (option && 'key' in option) {
      const selectedUser = uploadType.find((user: any) => user?.attributes?.user_name === option?.key);
      setError(null); 
      setFieldValue('userName', option?.key); 
      if (selectedUser) {
        setSelectedUser(selectedUser);
    }
  }
    }

    const handleSearchWrapper = (setFieldValue: Function) => (searchValue: string) => {
      handleSearch(searchValue, setFieldValue);
  };
  

    const handleSearch = (searchValue: string,setFieldValue:Function) => {
      if (searchValue.includes('@')) {
        if (isEmailValid(searchValue)) {
          setFieldValue('userName',searchValue); 
          setSelectedUser(searchValue)
            setError(null); // Clear any previous error message
          } else {
              setError('Please enter a valid email address.');
          }
      }
  };

  const userNameValidate = (errors:any) => {
    if (Object.prototype.hasOwnProperty.call(errors, 'userName') || error) {
      const errorMessage = errors.userName ? errors.userName : "error";
     console.log(errorMessage,"errorMessage>")
      return (
        <Grid style={{ color: 'red', textAlign: 'left', height: '5px', fontSize: '12px', fontWeight: 'bold' }}>
          {errorMessage}
        </Grid>
      );
    }
  
    // Return null if there are no errors
    return null;
  };

    const setSplitProfileImage = (split: any) =>{
      if(split.profile_image) {
        return split.profile_image
      } else{
        return defaultProfile
      }  
    }

    const postSplits = async() =>{

      let splitUsers: any

      if(splits.length > 0){
        splitUsers = splits.map((user: any) => {
           return {
             account_id: user.account_id,
             artist_name: user.userName,
             user_track_role_id: user.user_role_id,
             percentage: user.split
           }
        })
      }
       
      console.log(splitUsers,' splitUsers')

      const data = {
        data : splitUsers
      }

      const headers = {
        "Content-Type": "application/json",
        'token': token
      };

      await axios.put(baseUrl + '/bx_block_audiomusic/track_split?id=' + trackID ,data,{ headers }

    )
    .then((response: any) => {
        openNotification('Splits Added Successfully' , 'success')
        setActiveStep();
      }).catch((err: any) => {
        console.log(err.response, 'errors')
        openNotification(err?.response?.data?.error , 'error')
      });
         
    }

    const getUploadType = async (token: any) => {
      setLoading(true);
      await axios.get(`${baseUrl}/account_block/search_user?search=`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            
            token: token,
          },
        })
        .then((response: any) => {
          setUploadType(response.data.data);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
  
          openNotification(err.response.data.errors[0].token, "Error");
        }).finally(() => {
  
        })
    };
console.log(loading,uploadType,"addsplits")

    const getUploadedTrackid = async () =>{
      const trackId = await getStorageData('uploaded_trackId')
      if(trackId){
        setTrackId(trackId)
      }
    }

    const getCustomLibraryTrack = async () =>{
      const track = await getStorageData('uploaded_file_exists')
      if(track?.id){
        const new_splits = track.attributes.user_tracks.data.map((split: any) => {
          return {
            account_id: split.attributes.account_id,
            userName: split.attributes.artist_name,
            profile_image: split.attributes.artist_image,
            split: split.attributes.percentage,
            role: split.attributes.user_role
          }
        })
        setSplits(new_splits)
      }
    }

    const backClickCheck = () =>{
      redirectSplit('0');
    }

    
     useEffect(() =>{
      getUploadedTrackid()
      getCustomLibraryTrack()
      getUploadType(token);
    }, [])

    

  return (

<>

<Grid container className='UploadMainScroll'>
          
    <div className='main-div'>
        <Formik
          
          
          initialValues={{
            userName: '',
            role: '',
            split: ''
          }}

          validate={values => {
            let errors: FormikErrors<any> = {};
            if (!values.userName  && !isSplitsCompleted) {
              errors.userName = 'User Name is Required';
            } 
            else if(!values.role && !isSplitsCompleted){
              errors.role = 'Role is Required';
            }
            else if(!values.split && !isSplitsCompleted){
              errors.split = 'Required';
            }
            else if (!/^[1-9]\d*$/.test(values.split) && !isSplitsCompleted) {
              errors.split = 'Enter Number';
            }

            return errors;
          }}

          onSubmit={(values: any, actions ) => {onFinish(values, actions) }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            handleBlur,
            setFieldValue,
            initialValues,
            getFieldProps,
          }) => (
           
              
              <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <div style={{display: 'flex'}} className = "track-details-div">

                    <Grid container item xs={12} md={12} lg = {7} className="flex-direction-grid" style={{padding: '50px', marginTop: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Grid item className= {`Frame-Split justify-audio  margin-left-main upload-cover-margin`} style={{height: '275',width: '335', borderTopLeftRadius: '10px',borderTopRightRadius: '10px' , justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'blue',borderColor: 'blue'}}>
                        {
                          <Grid item container   style={{borderRadius: '10px',height: '80%',width: '100%',backgroundColor: '#141414'}}>
                              <Grid item xs={12} className= {`splits-username ${getFieldProps('userName').value != '' ? 'splitUserNameWithMarginTop' : 'splitUserNameWithOutMarginTop'}`} style={{padding: '15px'}}>

                              <FormAntd.Item
                                      // label="Username"
                                      className="trackSplit_Userinput place_holder_color colour-select-role responsive-split-user-margin"
                                      style={{borderBottom: '3px solid #323030', marginRight: '5px'}}
                                  >

                                {
                                  getFieldProps('userName').value != '' && <Grid className='value-label'>Username </Grid>
                                }
                                
                                {/* <TextField
                                      id="input-with-icon-textfield"
                                      data-testid="Username_change_in_addsplits_form"
                                      //label="TextField"
                                      className= {classes && classes.root}
                                      fullWidth
                                      placeholder='Username / Invite Email'
                                      InputProps={{
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              <img style={{height: '35px', marginBottom: '15px', marginLeft: '10px'}} src={userIcon}/>
                                          </InputAdornment>
                                      ),
                                      }}
                                      variant="standard"
                                      onChange={(e: any) => {
                                        setFieldValue('userName', e.target.value) }}   
                                      
                                  /> */}

                                <SelectAnt
                                  className="custom-select-ant-username"
                                  showSearch
                                  placeholder={ <b >Username / Invite Email</b> }
                                  optionFilterProp="children"
                                  value={values.userName || undefined}
                                  data-testid="Username_change_in_addsplits_form"
                                  onSearch={handleSearchWrapper(setFieldValue)}
                                  suffixIcon={<img style={{height: '35px', marginBottom: '15px', marginLeft: '10px', borderRadius: '50%', width: '35px', objectFit: 'cover'}} src={selectedUser?.attributes?.avatar != null ?  selectedUser.attributes.avatar : defaultProfile}/>}
                                  onChange={(values, option) => { selectName(option, setFieldValue)
                                  }}
                                  >
                                    {
                                      uploadType.map((user: any) =>{
                                        return <Option key={user?.attributes?.user_name} value={user?.attributes?.user_name}>{user?.attributes?.user_name}</Option>
                                      })
                                      
                                    }
                                    {/* <Option value="producer" >Producer</Option>
                                    <Option value="artist" >Artist</Option>

                                    <Option value="composer" >Composer</Option>
                                    <Option value="director" >Director</Option> */}


                                   </SelectAnt>
                                </FormAntd.Item>
                                  {
                                  userNameValidate(errors||error)
                                  }
                                  
                              </Grid>

                              <Grid item xs={12} className='flex-direction-splits' style={{display: 'flex', padding: '15px',width: '100%'}}> 
                                <Grid item  className="role-width" style={{}} >


                                  <FormAntd.Item
                                      // label="Username"
                                      className="trackSplit_input place_holder_color colour-select-role responsive-split-user-margin"
                                      style={{borderBottom: '3px solid #323030', marginRight: '5px'}}
                                  >

                                    {
                                  getFieldProps('role').value != '' && <Grid className='value-label' style={{marginLeft: '10px'}}>Role </Grid>
                                  }

                                <SelectAnt
                                //className="trackSplit_input"
                                  showSearch
                                  placeholder={<b>Select Role</b>}
                                  optionFilterProp="children"
                                  data-testid="Role_change_in_addsplits_form"
                                  //onChange={onChange}
                                  //onSearch={onSearch}
                                  // filterOption={(input, option) =>
                                  //   (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                  //   }
                                  value={values.role || undefined}
                                  suffixIcon={<img style={{marginLeft: '5px'}} src={ArrowIcon} />}

                                  onChange={(values) => {
                                    setFieldValue('role', values)
                                  }}
                                  >
                                    <Option value="5" >Producer</Option>
                                    <Option value="1" >Artist</Option>
                                    <Option value="2" >Composer</Option>
                                    <Option value="3" >Engineer</Option>
                                    <Option value="4" >Musician</Option>
                                    <Option value="6" >Writer</Option>


                                  </SelectAnt>

                                </FormAntd.Item>

                                      {
                                  errors.role && <Grid style={{color: 'red',textAlign: 'left', marginTop: '-20px', fontSize: '12px', fontWeight: 'bold'}}>{errors.role} </Grid>
                                  }

                                </Grid>

                                  <Grid item className='splits-width' style={{}}>

                                  {
                                  getFieldProps('split').value != '' && <Grid className='value-label' style={{marginLeft: '0px'}}>Split % </Grid>
                                  }
                                  {
                                    getFieldProps('split').value == '' && getFieldProps('role').value != '' && <div style={{marginTop: '15px'}}> </div>
                                  }
                                  <TextField
                                      id="input-with-icon-textfield"
                                      //label="TextField"
                                      className= {`${classes && classes.root} splits-placeholder`}
                                      fullWidth
                                      placeholder='Splits %'
                                      value={values.split || ''}
                                      variant="standard"
                                      onChange={(e: any) => {
                                        setFieldValue('split', e.target.value) }}   
                                      
                                  />
                                  {
                                  errors.split && <Grid style={{color: 'red',textAlign: 'left', width: '105px', fontSize: '12px', fontWeight: 'bold'}}>{errors.split} </Grid>
                                  }
                                </Grid>
                              </Grid>
                          </Grid>
                        }

                      
                        <Grid item  style={{borderBottomLeftRadius: '30px',borderBottomRightRadius: '30px', width: '100%', color: 'white', fontFamily: 'Inter', fontSize: '18px', fontWeight: 'bold', padding: '15px'}}>
                          <Button test-id="AddSplits_form_submit_test_id" type='submit' variant='text' style={{fontFamily: 'Inter', fontSize: '18px', fontWeight: 'bold',color: 'white', textTransform: 'capitalize', height: '5px', width: '100%'}}>
                            Add Split
                         
                          </Button>
                        </Grid>

                    </Grid>

                    {
                 splits?.length != 0 &&
                    <Grid item style={{width: '335px', marginTop: '50px', height: '305px', overflowY: 'auto'}}>
                    {
                      
                      
                      splits?.length != 0 &&
                      splits?.map((split: any) =>{
                        return <Grid container style={{display: 'flex', border:'3px solid #141414', justifyContent: 'space-between',padding: '5px',alignContent: 'center', alignItems: 'center', borderRadius: '15px', marginBottom: '5px'}}>
                          <Grid item style={{display: 'flex'}}>
                            <Grid item><img style={{height: '35px', marginTop: '5px', borderRadius: '50%', width: '35px', objectFit: 'cover'}} src={ setSplitProfileImage(split) } /> </Grid>
                            <Grid item style={{display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
                              <Grid style={{color: 'white', fontSize: '14px', fontFamily: 'karla', fontWeight: 'bold'}}>{split.userName}</Grid>
                              <Grid style={{color: '#686262', fontSize: '12px', fontFamily: 'karla', fontWeight: 'normal'}}>{split.role}</Grid>
                            </Grid>
                          </Grid>

                          <Grid item> 
                            <Grid style={{display: 'flex'}}> 
                              <Grid style={{color: 'white', marginRight: '10px', fontSize: '18px', fontWeight: 'bold'}}>{split.split}%</Grid>
                              <Grid data-testid="Remove_splits_in_addsplits_form" onClick={() => removeSplits(split)}> <img src={removeSplitIcon} /> </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                      })
                      
                    }
                    </Grid>
}
                    </Grid>

                    
                  {/* </div> */}

                  <Grid container item xs={12} md={12} lg = {5} style={{justifyContent: 'center'}} className="hide-side-image-in-responsive">
                    <Grid item className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                      <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414',width: '100%', height: '100%'}} src={AddSplitsCover} />

                      <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <div className=' name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Run Me</div>
                      <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> My Money </p>


                      <Button type='button' className='buttons-font-weight' test-id="post_splits_test_id" style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform:'capitalize', marginBottom: '10px'}} onClick={() =>{postSplits()}}>Next</Button>
                      <Button type='button'  className='buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '0px 0px 100px 25px rgba(0,0,0,.8)', fontWeight: 'bold', textTransform:'capitalize', marginBottom: '10px'}}>Save</Button>
                      <Button type='button'  variant="text"  test-id="back_splits_test_id" className='buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform:'capitalize'}} onClick={() => backClickCheck()}>Back</Button>
                      </div>
                      </div>
                    </Grid>
                    
                  </Grid>

                  <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                    <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                      <Button test-id="post_splits_test_id_1" style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform:'capitalize'}}  onClick={() =>{postSplits()}} >Next</Button>
                      <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform:'capitalize'}}>Save</Button>
                      <Button variant="text"  test-id="back_splits_test_id_1" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform:'capitalize'}} onClick={() => backClickCheck()}>Back</Button>

                    </div>

                  </Grid>

               </div>
             </form>

            )}

        </Formik> 
      {/* </Grid> */}

     

    </div>


 </Grid>
 </>

   )
}

export default AddSplits
// Customizable Area End
