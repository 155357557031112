// Customizable Area Start
import React from "react";
import AudioVideoSplitController, {
    Props,
} from "./AudioVideoSplitController.web";
import "./AudioVideoSplit.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import UserCard from '../Common/UserCard.web';
import AudioVideoCard from "../Common/AudioVideoCard.web";
import RightPanel from "../Common/RightPanel.web";
import { SelectSplitRightBg } from "../assets";
import { AudioContextApi } from '../../AudioContext/AudioContext.web'; 

// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class AudioVideoSplit extends AudioVideoSplitController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, users, selectedUser, media, splitType } = this.state;
        const {isPlaylist} = this.context;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="AudioSplitContent" style={{height: isPlaylist ? '75vh' : '90vh' }}>
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >
                                                {/* Music Detail Start*/}
                                                <AudioVideoCard
                                                    media={media}
                                                    type={splitType}
                                                    onPlay={() => { }} />
                                                {/* Music Detail End*/}
                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT24">
                                                        <div className='CommonText InfoTextSm'>Select The User You Want</div>
                                                        <p className='CommonText InfoTextSm'>To Buy Split From.</p>
                                                    </div>
                                                </Hidden>
                                                {/* User Select */}

                                                {users?.map((user: any) => {
                                                    return (
                                                        <UserCard

                                                            key={user.id}
                                                            user={user}
                                                            selected={selectedUser.id === user.id}
                                                            clickable={true}
                                                            onClickUser={this.onUserChange}
                                                        />
                                                    );
                                                })}
                                                {/* User Select END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5}>
                                            <RightPanel
                                                line1={`Select The User You`}
                                                line2={`Want To Buy Split From.`}
                                                backgroundImage={SelectSplitRightBg}
                                                onNextClick={this.onNextClick}
                                                onBackClick={this.onBackClick}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default AudioVideoSplit;
AudioVideoSplit.contextType = AudioContextApi;
// Customizable Area End