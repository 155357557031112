// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";

import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";

import { setStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../../../dashboard/src/config.js");


export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    setBackStep: () => void;
}
interface S {
    token: any;
    loading: any;
    scaned: any;
    audioFile: any;
    videoFile: any;
    uploadType: any;
    coverImage: any;
    linkedId: any;
    AudioVideoList: any;
    SearchInput: any;
}
interface SS {
    id: any;
}

export default class LinkTrackController extends BlockComponent<Props, S, SS> {
    getLinkAudioListApiId: string = ""

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            scaned: false,
            audioFile: "",
            videoFile: "",
            uploadType: window.location.href.split('/')[4],
            coverImage: "",
            linkedId: '',
            AudioVideoList: [],
            SearchInput: ''
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getAudioVideoLinkList();
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.SearchInput !== prevState.SearchInput) {
          this.getAudioVideoLinkList();
          console.log('audio video calll link')
        }
    }
   
     setValue = async(e: any) =>{
        this.setState({linkedId: e})
        await setStorageData('uploaded_linkedID', e)
      }


   debounceLink = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

   debounceLogLink = this.debounceLink((text: any) => this.haldleLinkSearchInput(text), 1000);

    searchLinkInput = (e: any) =>{
        this.debounceLogLink(e.target.value)
      }

    haldleLinkSearchInput = (text: any) =>{
       this.setState({SearchInput: text})
      }

      getAudioVideoLinkList = () =>{
        const uploadType = window.location.href.split('/')[4]

        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': localStorage.getItem('token')
        };
    
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getLinkAudioListApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
                `/bx_block_audiomusic/link_track?title=${this.state.SearchInput}&upload_type=${uploadType == 'audio'? "Video" : 'Audio'}`
      
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      handleAudioVideoLinkResponse = (responseJson: any) =>{
         if(responseJson?.data?.length > 0){
            this.setState({AudioVideoList: responseJson?.data})
         }else{
            this.setState({AudioVideoList: []})
         }
      }

      async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
         if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.getLinkAudioListApiId
      
              ) {
                if (responseJson !== undefined && !responseJson.errors) {
                   this.handleAudioVideoLinkResponse(responseJson)
                }
             }
         }

        }
}
    
}
// Customizable Area End