// Customizable Area Start
import {  SearchOutlined } from "@ant-design/icons";
import {  Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";
import "blocks/dashboard/assets/css/HomeHeader.css";
import { searchIcon, Homeblue, Downloadblue, Chartsblue, Playlistblue, Creativeblue, Videoblue,  Homeicon, Chartsicon, Videoicon, Playlisticon, DownloadIcon, Creativesicon, originalIcon } from "blocks/dashboard/src/assets";
import {
  Hidden,
  IconButton,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import axios from "axios";
import HomeHeaderDropdown from "./HomeHeaderDropdown.web";
export const configJSON = require('blocks/dashboard/src/config');
const HomeHeader = (props: any) => {

  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dialogText, setDialogText] = useState("")
  const [open, setOpen] = useState(false)
  const [currentKeyCharts, setCurrentKeyCharts] = useState("3")
  const [currentKeyPlaylist, setCurrentKeyPlaylist] = useState("5")
  const userType:any = localStorage.getItem("user_type")

  const { haldleSearchInput } = props;
  const [originals, setOriginals] = useState("")
 



  useEffect(() => {
    if (token) {
       getOriginals()
    }
  }, []);

  const getOriginals = async () => {
    await axios.get(`${configJSON.baseUrl}/bx_block_audiomusic/original_profile`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setOriginals(response?.data?.data?.id)
      }).catch((err) => {
        console.log(err);
      });
  }





  useEffect(() => {
    let items = [
      { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
      { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
      { key: "3", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
      { key: "4", label: "Playlist", path: "/home/all-playlist", icon: Playlistblue, defaultIcon: Playlisticon },
      { key: "5", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
      { key: "6", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon },
      { key: "7", label: "Comments & DM", path: "/comments", icon: Creativeblue, defaultIcon: Creativesicon }
    ];
    if (userType === "original") {
      setCurrentKeyCharts("2")
      setCurrentKeyPlaylist('4')
    }
  }, [userType])


  const token: any = localStorage.getItem("token");

  const handleMenu = (event: any) => {

    setAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
 

  const subscriptionPlan = localStorage.getItem("subscription_plans")

  const openSearchScreen = () => {
    if (token) {
      const getSlug = window.location.href.split('/')
      if (getSlug[4] == 'search') {
        if (getSlug.length != 0 && getSlug[5] != '') {
          if (getSlug[5] == 'beats') {
            if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
              setDialogText("3")
              setOpen(true)
            }
            else {
              history.push("/home/search/beats")
            }
          }
          else if (getSlug[5] == 'hooks') {
            if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
              setDialogText("3")
              setOpen(true)
            }
            else {
              history.push("/home/search/hooks")
            }

          } else if (getSlug[5] == 'verses') {
            if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
              setDialogText("3")
              setOpen(true)
            }
            else {
              history.push("/home/search/verses")
            }
          }
          else if (getSlug[5] == 'songs') {
            history.push("/home/search/songs")
          }
          else if (getSlug[5] == 'videos') {
            history.push("/home/search/videos")
          }
          else if (getSlug[5] == 'playlist') {
            history.push("/home/search/playlist")
          }
          else if (getSlug[5] == 'users') {
            history.push("/home/search/users")
          }

        }

        else {
          if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
            history.push("/home/search/songs")
          }
          else {
            history.push("/home/search/beats")
          }
        }


      } else {
        if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
          history.push("/home/search/songs")
        }
        else {
          history.push("/home/search/beats")
        }
      }


    } else {
      history.push('/Welcome')
    }
  }

  const debounce = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

  const debounceLog = debounce((text: any) => haldleSearchInput(text), 1000);

  const changeSearchInput = (e: any) => {
    if (token) {
      const getSlug = window.location.href.split('/')
      if (userType?.includes("listener") && subscriptionPlan == "NXZ" && ((getSlug[5] == 'beats') || (getSlug[5] == 'hooks') || (getSlug[5] == 'verses'))) {
        setDialogText("3")
        setOpen(true)
      }
      else {
        if (e.target.value.trim() != '' || e.target.value == '') {
          debounceLog(e.target.value)
        }
      }
    } else {
     
      history.push('/Welcome')
    }
  }


  return (
    <Row className="main-header  width_100 header_no_flex_wrap">
      <Col lg={0} xl={0} sm={2} md={2} xs={5}>
        <Row >
          <Hidden mdUp>

            <IconButton
              style={{
                color: "white",
              }}
            >
              <MenuIcon className="menu-icon" style={{ width: '3rem', height: '2rem' }} onClick={handleMenu} />
            </IconButton>
          </Hidden>

        </Row>
      </Col>
      {/* Left part */}
      <Col lg={12} xl={10} sm={8} md={11} xs={4}>

        <div className={`search-icon`} onClick={openSearchScreen}>
          <img src={searchIcon} style={{ height: '25px', width: '25px' }} />
        </div>

        <Row className={`search_input ${window.location.href.includes('inbox') && 'hide_search_bar_in_comments'}`} >
          <Input
            onChange={(e: any) => changeSearchInput(e)}
            onClick={openSearchScreen}
            className=" ml-2 "
            prefix={
                      //@ts-ignore
              <SearchOutlined
                // translate="true"
                className="text_white1 search_icon"
                // rev={undefined} 
                // translate={undefined}             
                 />
            }
            style={{ width: "100%" }}
            placeholder="Search Anything you like"
          />

        </Row>
      </Col>
     <HomeHeaderDropdown sizexl={9} dmActive={false} anchorEl={anchorEl} handleClose={handleClose} dialogText={dialogText} handleLoginClose={handleLoginClose} open={open} currentKeyCharts={currentKeyCharts} setCurrentKeyCharts={setCurrentKeyCharts} 
      currentKeyPlaylist={currentKeyPlaylist}
      setCurrentKeyPlaylist={setCurrentKeyPlaylist}
      />
    </Row>
  );
};

export default HomeHeader;
// Customizable Area End