// Customizable Area Start
import { Content } from "antd/lib/layout/layout";
import { Layout } from "antd";
import { Tab, Tabs, Hidden, Grid } from "@material-ui/core";
import React from "react";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import CtrlController from "./CtrlController.web";
import Snapshot from "./Snapshot/Snapshot.web";
import Splits from "./Splits/Splits.web";
import Tracks from "./Tracks/Tracks.web";
import Songs from "./Songs/Songs.web";
import Videos from "./Ctrlvideos/Ctrlvideos.web";
import Ctrlplaylist from "./Ctrlplaylist/Ctrlplaylist.web";
import "./Snapshot/snapshot.css";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";

const tabName = [
  {
    tabName: "Snapshot",
    value: 0,
  },
  {
    tabName: "Splits",
    value: 1,
  },
  {
    tabName: "Tracks",
    value: 2,
  },
  {
    tabName: "Songs",
    value: 3,
  },
  {
    tabName: "Videos",
    value: 4,
  },
  {
    tabName: "Playlist",
    value: 5,
  },
];

export default class Ctrl extends CtrlController {
  constructor(props: any) {
    super(props);
  }

  screen = () => {
    if (this.state.active == 0) {
      return (
        <Snapshot
          checkAllDownloaders={this.checkAllDownloaders}
          seeAllTopDownloaders={this.state.seeAllTopDownloaders}
        />
      );
    }
    if (this.state.active == 1) {
      return <Splits navigation={undefined} id={""} key={""} 
      topPlayMusic={this.topPlayMusic}
      getStatusDetails={this.getStatusDetails}
      />;
    }
    
    if (this.state.active == 2) {
      return <Tracks navigation={undefined} id={""} key={""} 
      topPlayMusic={this.topPlayMusic}
      getStatusDetails={this.getStatusDetails}
      />;
    }
    if (this.state.active == 3) {
      return <Songs navigation={undefined} id={""} 
      topPlayMusic={this.topPlayMusic}
      getStatusDetails={this.getStatusDetails}
      />;
    }
    if (this.state.active == 4) {
      return <Videos navigation={undefined} id={""} 
      getStatusDetails={this.getStatusDetails}
      />;
    }
    if (this.state.active == 5) {
      return <Ctrlplaylist navigation={undefined} id={""}
      getStatusDetails={this.getStatusDetails} data={undefined}      
      topPlayMusic={this.topPlayMusic}
      showSingleplaylistdeatils={this.state.showSingleplaylistdeatils}
      setShowSingleplaylistdeatils={(data:any)=>this.setShowSingleplaylistdeatils(data)}
      
      />;
    }
  };

  render() {
    const { isPlaylist } = this.context;

    interface StyledTabsProps {
      value: number;
      onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    }

    const StyledTabs = withStyles({
      indicator: {
        display: "none",
      },
    })((props: StyledTabsProps) => (
      <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
    ));

    interface StyledTabProps {
      label: string;
      // className:string;
    }

    function a11yProps(index: any) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    const StyledTab = withStyles((theme: Theme) =>
      createStyles({
        root: {
          textTransform: "none",
          color: "#fff",
          fontSize: "16px",
          borderRadius: "35px",
          fontWeight: 700,
          backgroundColor: "#212121",
          padding: "3px 5px ",
          margin: "0px 2px ",
          "&:focus": {
            // opacity: 1,
            backgroundColor: "red",
          },
        },
      })
    )((props: StyledTabProps) => <Tab disableRipple {...props} />);
    return (
      //Merge Engine DefaultContainer
      <Layout
        className={
          this.state.seeAllTopDownloaders
            ? "ctrl-top-main-grid-snapshot"
            : "ctrl-top-main-grid"
        }
     
      >
        {/* .......................SideBar...................................... */}
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <Content
          style={{
            margin: "0px",
            // height: "100%",
            height: "100vh",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between"
          }}
        >
          <HomeHeader />

          <div
            className={
              isPlaylist
                ? "mainBody-audio-withoutheight"
                : "mainBody-audio-withoutaudio"
            }
          >
            <div
              className="chats-header ctrl-tab-parent ctrl-tab-parent"
              // style={{minHeight:"10vh"}}
        id="yourTopDivId12"

            >
              <Grid container className="profile-tab-Container">
                <Grid item xs={12}>
                  <StyledTabs
                    value={this.state.active}
                    // onChange={this.handleChange}
                    aria-label="nxz-tabs"
                    // @ts-ignore
                    variant="scrollable"
                  >
                    {tabName.map((item: any,index:any) => {
                      return (
                        <StyledTab
                          label={item.tabName}
                          key={index}
                          //@ts-ignore
                          onClick={()=>this.handleChange(item.value)}
                          className={
                            this.state.active == item.value
                              ? "active-tab"
                              : "non-active-tab"
                          }
                          {...a11yProps(item.value)}
                        />
                      );
                    })}
                  </StyledTabs>
                </Grid>
              </Grid>
            </div>
            {this.screen()}
          </div>
        </Content>
      </Layout>

      //Merge Engine End DefaultContainer
    );
  }
}
Ctrl.contextType = AudioContextApi;
// Customizable Area End
