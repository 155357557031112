// Customizable Area Start
import React from "react";
import OfferPreviewController, {
    Props,
} from "./OfferPreviewController.web";
import "./OfferPreview.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, Box, Typography, Avatar, IconButton } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import { DealPreviewLeftAvatar, OfferPreviewRightBg } from "../assets";
import { userLogo, CircledRight, videoOffer, MusicCover, Playcirclefill } from '../../assets'
import { AudioContextApi } from '../../AudioContext/AudioContext.web'
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class OfferPreview extends OfferPreviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, type, selectedUser, amount, percentage ,date,media} = this.state;
        const {isPlaylist} = this.context;
        console.log("userselelct", media?.image)
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="AudioSplitContent" style={{height: isPlaylist ? '75vh' : '90vh' }}>
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >

                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>Review & Confirm</div>
                                                        <p className='CommonText InfoTextSm'>Your Offer.</p>
                                                    </div>
                                                </Hidden>
                                                {/* Offer Preview START*/}
                                                <Box
                                                    className="OfferPreviewBox"
                                                >
                                                    {/* Label */}
                                                    <Box className="LabelBox">
                                                        <Box
                                                            className="AvatarBox"
                                                        >
                                                            <Avatar src={this.state.loggedInUser?.attributes?.avatar}
                                                                className="Avater" />
                                                        </Box>
                                                        <Box
                                                            className="LabelInfoBox">
                                                            <Typography className="PVCommonText LabelTitleText">
                                                                {this.state.loggedInUser?.attributes?.user_name}
                                                            </Typography>
                                                            <Typography
                                                                className="PVCommonText LabelSubTitleText"
                                                            >
                                                                {this.state.loggedInUser?.attributes?.user_type}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* Label */}
                                                    <Box className="MT32">
                                                        <Typography className="PVCommonText OfferingText">Is Offering:</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography className="PVCommonText OfferingMoneyText">${amount}</Typography>
                                                    </Box>
                                                    <Box className="MT23">
                                                        <Typography className="PVCommonText ForText">For:</Typography>
                                                    </Box>
                                                    <Box className="SplitDetailBox">
                                                        <Box>
                                                            <Avatar src={selectedUser?.artist_image || selectedUser[0]?.artist_image} className="Avatar" />
                                                            <Typography className="SplitPercentageText">
                                                               {Number(selectedUser?.percentage || selectedUser[0]?.percentage) - Number(percentage)}%
                                                            </Typography>
                                                        </Box>
                                                        <Box className="LineBox">
                                                            <Box className="ChildBox">
                                                                <Box className="Line">
                                                                    <Avatar src={CircledRight} className="Avatar" />
                                                                </Box>
                                                            </Box>
                                                            <Box className="SplitDurationBox">
                                                                <Typography className="PVCommonText DurationText">FOR {date}</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Avatar src={this.state.loggedInUser?.attributes?.avatar} className="Avatar" />
                                                            <Typography className="SplitPercentageText">
                                                                {percentage}%
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="MT23">
                                                        <Typography className="PVCommonText FromText">From:</Typography>
                                                    </Box>
                                                    <Box className="PVAudioVideoContentDiv">
                                                        {type === 'user' ? (
                                                            <Box className="PVUserImageDiv">
                                                                <Avatar src={selectedUser?.artist_image || selectedUser[0]?.artist_image} className="Avatar" />
                                                            </Box>
                                                        ) : (
                                                            <Box className="PVAudioVideoImageDiv">
                                                                <img src={media?.image } className={`PVAudioVideoImage ${type === 'video' && 'VideoImage'}`} />
                                                                <IconButton onClick={() => { }} className={`PVAudioVideoPlayBtn ${type === 'video' && 'VideoType'}`}>
                                                                    <Avatar src={Playcirclefill} className="Icon" />
                                                                </IconButton>
                                                            </Box>
                                                        )}

                                                        <Box className="PVAudioVideoInfoDiv">
                                                            <Box className="PVSongInfoBox">
                                                                <Typography className="SongNameText">
                                                                    {type === 'user' ? "All Royalties" :media?.name}
                                                                </Typography>
                                                                <Typography className="SongWriterText">by:{media?.writer}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {/* Offer Preview END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                            <RightPanel
                                                line1={`Review & Confirm`}
                                                line2={`Your Offer.`}
                                                nextButtonName="Confirm"
                                                backgroundImage={OfferPreviewRightBg}
                                                onNextClick={this.onNextClick}
                                                onBackClick={this.onBackClick}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default OfferPreview;
OfferPreview.contextType = AudioContextApi;
// Customizable Area End