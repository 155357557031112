// Customizable Area Start
import React from 'react'
import { Grid, Hidden, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import AsideLeft from "../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import './index.web.css'
import { StartCover, PlusIcon, fire } from '../assets';
import { useHistory } from 'react-router-dom';
import { openNotification } from '../../../../components/src/Notification.web';
import { setStorageData } from '../../../../framework/src/Utilities';


function UploadTrack() {
    const history = useHistory();

    const userType:any = localStorage.getItem("user_type")
    const subscriptionPlan = localStorage.getItem("subscription_plans")

    const authToken: any = localStorage.getItem("token")
    console.log(userType, subscriptionPlan, 'localstorage values')

    if (!authToken) { 
      history.push('/home')
    }
    
    if (userType?.includes("listener") && subscriptionPlan == "NXZ") {
      history.push('/home')
    }

  

   const nextClick = () =>{
     openNotification('Please select audio or video type', 'error')
   }

   const audioRedirection = async() =>{
     await setStorageData('activeStepUpload', '0');
     history.push('/uploadTrack/audio');
   }

   const videoRedirection = async() =>{
     await setStorageData('activeStepUpload','0');
     history.push('/uploadTrack/video');
   }
    
  return (
    <>
   

    <div
      style={{
        //maxWidth: "100vw",
        overflow: "hidden",
        //overflowY: 'auto',
        backgroundColor: "#171717",
       // height: "100vh",
      }}
    >
      
      <Layout style={{ backgroundColor: "#171717", height: "100vh"}}>
        
        {/* .......................SideBar...................................... */}
        {/* <AsideLeft /> */}
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <Content
          style={{
            margin: "0px",
            //height: "100%",
            backgroundColor: "rgb(5 4 4)",
            display: "flex",
            flexDirection: "column",
            //overflowY: 'auto'
          }}
        >
          <HomeHeader />

     
         <Grid container className='UploadMainScroll'>
          
           <div className='main-track-div'>
            <Grid item xs={12} sm={12} md={6} lg={7} className='img-grid-width'>
                   <div style={{justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      <div className='Frame-134 main-track-margin' style={{alignItems: 'center',justifyContent: 'center', padding: '50px',color: 'white',backgroundColor: '#060404',cursor: 'pointer'}} onClick={() =>{audioRedirection()}}>
                        <img src={PlusIcon} style={{height: '26.7px', width: '26.7px'}}/> <p className='audio'>Audio</p> 
                      </div>
                      <div className='Frame-134' style={{alignItems: 'center',justifyContent: 'center',marginTop: '5px', padding: '50px',color: 'white', backgroundColor: '#060404',cursor: 'pointer'}} onClick={() =>{videoRedirection()}}>
                      <img src={PlusIcon} style={{height: '26.7px', width: '26.7px'}}/> <p className='Video'>Video</p> 
                      </div>
                  </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg = {5} className="hide-side-image-in-Main-responsive" > 
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', position: 'relative'}}>
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414' , width: '100%', height: '100%'}} src={StartCover} />
                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                     <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <div className='main-track-name-position name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px'}}>Upload Something,</div>
                      <p className='main-track-name-position name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px'}}> And it Better Be<img src={fire}></img></p>


                      <Button className='main-track-name-position buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px',fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '15px'}} onClick={() =>{nextClick()}}>Next</Button>
                      <Button className='main-track-name-position buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}>Save</Button>
                    </div>
                   </div>
                </div>
                
            </Grid>

            <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-Main-screens" >
                <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                   <Button style={{backgroundColor:'red',padding: '19px 127px 19px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px'}} onClick={() =>{nextClick()}} >NEXT</Button>
                   <Button style={{backgroundColor:'#212121',padding: '19px 127px 19px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px'}}>SAVE</Button>
                </div>
                
            </Grid>
            </div> 
         </Grid>
    </Content>
        
      </Layout>
      
    </div>
    
    </>
  )
}

export default UploadTrack
// Customizable Area End