// Customizable Area Start
import React from "react";
import VerifyDealController, {
    Props,
} from "./VerifyDealController.web";
import "./VerifyDeal.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, Box, Typography, Button } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import { VerifyDealRightBg } from "../assets";
import OtpInput from "react-otp-input";
import { AudioContextApi } from '../../AudioContext/AudioContext.web'
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class VerifyDeal extends VerifyDealController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading } = this.state;
        const {isPlaylist } = this.context;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="DVMainPanel" style={{height: isPlaylist ? '75vh' : '90vh' }}>
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >

                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>Verify And Send</div>
                                                        <p className='CommonText InfoTextSm'>Your Offer</p>
                                                    </div>
                                                </Hidden>
                                                {/* Importent Notice START*/}
                                                <Box
                                                    className="VDBox"
                                                >

                                                    <Box className="VFPhoneBox">
                                                        <Typography className="VFPhoneText">Verify your phone to</Typography>
                                                    </Box>
                                                    <Box className="SOBox">
                                                        <Typography className="SOText">SEND OFFER</Typography>
                                                    </Box>
                                                    <Box className="OTPBox">
                                                        <OtpInput
                                                            shouldAutoFocus={true}
                                                            isInputNum={true}
                                                            onChange={this.handleOtpChange}
                                                            value={this.state.otpValue}
                                                            numInputs={4}
                                                            inputStyle={{
                                                                style:{
                                                                    background:'black',
                                                                    color:'white'
                                                                }
                                                            }}
                                                            focusStyle={{ outline: 'none' }}
                                                        />
                                                    </Box>
                                                    <Box className="ResendBox">
                                                        <Typography className="NoOtpText">
                                                            Did'nt get OTP?
                                                        </Typography>
                                                        <Button
                                                            data-test-id="RESEND_BTN"
                                                            onClick={this.resendOTP}
                                                            className="ResendBtn"
                                                        >
                                                            RESEND
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                {/* Importent Notice END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                            <RightPanel
                                                line1={`Verify And Send`}
                                                line2={`Your Offer`}
                                                nextButtonName="Verify"
                                                backgroundImage={VerifyDealRightBg}
                                                onNextClick={this.verifyOtpAndContinue}
                                                onBackClick={this.onBackClick}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default VerifyDeal;
VerifyDeal.contextType = AudioContextApi;
// Customizable Area End