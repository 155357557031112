// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";

const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export interface Props {
    navigation?: any;
    id?: string;

    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    userType: any;
    subscriptionPlan: any;
    errorMsg: string;
    loading: boolean;
    step: any;
    allPlaylists: any;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class AddTrackController extends BlockComponent<Props, S, SS> {
    getPlayListId: string = "";

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            userType: localStorage.getItem("user_type"),
            subscriptionPlan: localStorage.getItem("subscription_plans"),
            errorMsg: "",
            loading: false,
            step: 0,
            allPlaylists: []
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {
        super.componentDidMount();
        this.getPlayList()
        this.getStepActive()
    }

    getStepActive = async() =>{
      const currentStep = await getStorageData('activeStepUpload')
       if(currentStep == '0'){
        const number = parseInt(currentStep)
        this.setState({step : number})
       }else{
        this.setState({step : currentStep})
       }
    }

    setStep = async(step :any) =>{
      if(step == 0){
        await setStorageData('activeStepUpload', '0')
      }else{
        await setStorageData('activeStepUpload', step)
      }
      this.setState({step : step})
    }


    getPlayList = () => {
        // Customizable Area Start
        const header = {
          "Content-Type": "application/json",
          'token': this.state.token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getPlayListId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          baseUrl + `${'/bx_block_playlist/play_lists'}`
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        // console.log(requestMessage.id, requestMessage, "method call")
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
      }


      async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.getPlayListId
      
              ) {
                if (responseJson !== undefined && !responseJson.errors) {
                  this.setState({ allPlaylists: responseJson.data })
                }
              }
          }

        }
}
}
// Customizable Area End