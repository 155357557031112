// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";
import axios from 'axios'
import { openNotification } from '../../../../components/src/Notification.web';
import { setStorageData , removeStorageData} from "../../../../framework/src/Utilities";

export const configJSON = require("../../../dashboard/src/config.js");
const baseUrl = configJSON.baseUrl;

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    redirectSplit : (step: any) => void;
}
interface S {
    token: any;
    loading: any;
    scaned: any;
    recognization: any;
    fingerPrint: any;
    UploadFile: any;
    audioFile: any;
    videoFile: any;
    uploadType: any;
    trackFromCustomLibrary: any;
    validationText: any
}
interface SS {
    id: any;
}

export default class ScanProcessController extends BlockComponent<Props, S, SS> {

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            scaned: false,
            recognization: 0,
            fingerPrint: 0,
            UploadFile: 0,
            audioFile: "",
            videoFile: "",
            uploadType: window.location.href.split('/')[4],
            trackFromCustomLibrary: false,
            validationText: ""
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {
        super.componentDidMount();
        this.setState({trackFromCustomLibrary: false})
    }

    backClick = () =>{
        HISTORY.push('/uploadTrack')
    }

    afterNextClick = () =>{
      if(this.state.scaned){
        if(this.state.trackFromCustomLibrary){
          this.props.redirectSplit(5);
        }else{
          this.setState({trackFromCustomLibrary: false})
          this.props.setActiveStep();
        }
        this.setState({ validationText: ''})
      }
      else{
        this.setState({ validationText: 'please select any file to upload'})
      }
    }

     uploadMp3 = (options: any, uploadType: any) =>{
        console.log(options, 'mp3 file')

        const {  file } = options;

        console.log(uploadType, 'upload type test')
        if(uploadType == 'audio'){
          console.log(file.type, 'selected file');
          if(file.type.split("/")[0] == "audio"){
            this.setState({audioFile: file, validationText: ''})
            this.recognizeAudio(file)
          }else{
            this.setState({ validationText: 'Please Select audio file'})
          }
        }else if(uploadType == 'video'){
          if(file.type.split('/')[0] == 'video'){
            console.log('video file')
            this.setState({videoFile: file, validationText: ''})
            this.recognizeAudio(file)
          }else{
            this.setState({ validationText: 'Please Select video file'})
          }
        }
      }

    recognizeAudio = async(audioFile: any) =>{
       this.setState({loading: true})
        const fmData: any = new FormData();
          fmData.append('file', audioFile);

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': this.state.token
          };
          const headers = {
            "Content-Type": "application/json",
            'token': this.state.token
          };

          await axios.post(`${ baseUrl + '/bx_block_audiomusic/check_recognition_state'}`,fmData,{ headers }

        )
        .then((response: any) => {
           this.setState({loading: false})
           this.handleRecognizeResponse(response, audioFile)
          }).catch((err: any) => {

          });

       
    }


    handleRecognizeResponse = async(responseJson: any, file: any) =>{
       if(responseJson != null && responseJson?.data){
        if(responseJson?.data?.track){
          await setStorageData('uploaded_file_exists', responseJson?.data?.track?.data)
          await setStorageData('uploaded_trackId', responseJson?.data?.track?.data?.id)
          this.setState({trackFromCustomLibrary: true})
        }else{
          await removeStorageData('uploaded_file_exists')
          this.setState({trackFromCustomLibrary: false})
          await removeStorageData('uploaded_trackId')
        }
         this.setState({scaned: true, recognization: responseJson?.data?.recognition_percentage, fingerPrint: responseJson?.data?.fingerprint_percentage, UploadFile: 100})
          await setStorageData('uploaded_file', file)
         if(responseJson?.data?.message == "I own, and have rights required to upload this content"){
           openNotification(responseJson?.data?.message, 'success')
         }
       }

    }


}
// Customizable Area End