// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { createBrowserHistory } from "history";
import axios from 'axios';
import { openNotification } from "components/src/Notification.web";
import { HISTORY } from "components/src/common";

export const configJSON = require("./config");
const history = createBrowserHistory()

export interface Props {
  navigation?: any;
  id?: string;
  //new changes
  props?: any;
  myProfile?: boolean;
  userData?: boolean;
}

interface S {
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  //new changes
  window_width: any;
  video_window_width: any;
  handleAudioVideos: number;
  selected: any;
  playlistLoading: boolean;
  selectedPre: boolean;
  playList: any;
  songsList: any;
  value: number;
  activityList: any;
  musicButton: boolean;
  open: boolean,
  openScanQr: boolean,
  seeAll: boolean,
  TrackDetails: any;
  isHover: boolean;
  songByName: any;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  toggleComment: boolean;
  commentTabs: any;
  beatList: any;
  top_playlistVideo: any;
  playlistVideo: any;
  getTopPlaylists: any;
  apiToken: any;
  followerData: any;
  loader: boolean;
  creativeById: any;
  openPopupFollowers: boolean,
  openPopupFollowing: boolean,
  loadingFollow: boolean;
  unfollowState: boolean;
  userFollowerListData: any;
  visible: boolean;
  getTop100: any;
  beatErrorResponse: any;
  topTrackErrorResponse: any;
  playPlaylist: boolean;
  playlistData: any;
  checkType: any;
  selectedPlayList: any;
  openAlertPopUp: boolean;
  OpenUpgradModal: boolean;
  dialogText: any;
  slickCount: any;
  handletokenError: boolean;
  handleAudioVideo: any;
  creativeId: any;
  tabLoading: any;
  crativeIdtabLoading: any;
  playlistGraph: any;
  userType: any;
  subscription_plans: any;
  handleplay: boolean;
  filterValue: any;
  videoList: any;
  tabPanelList: any;
  playLists: any;
  tabName: any
  listenertabName: any
  profileCountData: any;
  gridSizes: any;
  new_released_video: any;
  new_released_video_attributes: any;
  get_All_Activity_data: any;
  getTopVideos: any;
  get_notification: any;
  getCreativeTabsLoader: any;
  followingData: any;
  allFollowerData: any;
  listenerValue: any;
  profileLoader: any;
  followstatuscheck: any;
  notificationId:any;
  notificationUser:any;
  notificationType:any;
}

interface SS {
  id: any;
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  //new changes
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getDataByType: string = "";
  getPlayListId: string = "";
  getTopVideoList: string = "";
  getFollowerListId: string = "";
  getCreativeListId: string = "";
  getactivityListId: string = "";
  getTop100List: string = "";
  getallPlaylistId: string = "";
  getFollowId: string = '';
  getUnFollowId: string = '';
  getUserFollowerListId: string = '';
  handleFilterApiCallId: any;
  userProfileData: any;
  newReleaseVideo: any;
  creativeProfileTrack: any;
  allActivityData: any;
  followingDataUser: any;
  followerDataUser: any;
  followUserById: any;
  handleunfollowUserById: any;
  handleFollowUser: any;
  handleUnfollowById: any;
  handlePlaylistData: any
  gettop100PlaylistsUser: any
  getAllUploadTypePlaylist: any;
  getAllNotification: any;
  getTop100UploadedType: any;
  getTopProfileVideoData: any
  getAllNotificationApiId:any;

  myRef: any;
  nextRef: any;
  vedioPlayer: any;
  previousHref: string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    //new changes
    this.myRef = React.createRef();
    this.nextRef = React.createRef();
    this.vedioPlayer = React.createRef()
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      //new changes
      gridSizes: {
        xsSize: 12,
        mdSize: 8,
        lgSize: 9,
        smSize: 6,
        xlSize: 4,
        xxlSize: 3,
      },
      window_width: 0,
      video_window_width: 0,
      selected: null,
      handleAudioVideos: 0,
      playlistLoading: true,
      selectedPre: false,
      handletokenError: false,
      slickCount: 0,
      beatErrorResponse: [],
      topTrackErrorResponse: [],
      checkType: "",
      openAlertPopUp: false,
      OpenUpgradModal: false,
      dialogText: "",
      playlistData: {},
      selectedPlayList: [],
      playPlaylist: false,
      dashboardData: [],
      creativeById: {},
      openPopupFollowers: false,
      openPopupFollowing: false,
      loader: false,
      playList: [],
      errorMsg: "",
      token: "",
      loading: false,
      value: 0,
      open: false,
      openScanQr: false,
      TrackDetails: 0,
      seeAll: false,
      isHover: false,
      songByName: [],
      musicButton: false,
      toggleComment: false,
      handleAudioVideo: 0,
      commentTabs: 0,
      beatList: [],
      playlistVideo: [],
      top_playlistVideo: [],
      getTopPlaylists: [],
      getTop100: [],
      handleplay: false,
      getTopVideos: [],
      getCreativeTabsLoader: false,
      followingData: [],
      profileCountData: [{
        title: "Plays",
        count: "0",
        percentage: "0"
      },
      {
        title: "Views",
        count: "0",
        percentage: "0"
      },
      {
        title: "Reposts",
        count: "0",
        percentage: "0"
      }
      ],
      playlistGraph: [{
        id: 1,
        title: "Followers",
        value: "0"
      },
      {
        id: 2,
        title: "Plays",
        value: "0"
      },
      {
        id: 3,
        title: "Reposts",
        value: "232.4k"
      },
      {
        id: 4,
        title: "Comments",
        value: "0"
      },
      ],
      videoList: [],
      playLists: [],
      new_released_video: [],
      new_released_video_attributes: [],
      get_notification: {},
      get_All_Activity_data: [],
      allFollowerData: [],
      activityList: [],
      tabName: [
        {
          tabName: "Beats",
          value: 0
        },
        {
          tabName: "hooks",
          value: 1
        },
        {
          tabName: "Verses",
          value: 2
        },
        {
          tabName: "Songs",
          value: 3
        },
        {
          tabName: "Videos",
          value: 4
        },
        {
          tabName: "Activity",
          value: 5
        },
        {
          tabName: "Playlists",
          value: 6
        },

      ],
      listenerValue: 0,
      listenertabName: [

        {
          tabName: "Activity",
          value: 0
        },
        {
          tabName: "Playlists",
          value: 1
        },

      ],
      songsList: [],
      apiToken: localStorage.getItem("token"),
      followerData: '',
      loadingFollow: false,
      unfollowState: false,
      userFollowerListData: [],
      visible: false,
      creativeId: "",
      tabLoading: false,
      crativeIdtabLoading: false,
      profileLoader: 0,
      userType: localStorage.getItem("user_type"),
      subscription_plans: localStorage.getItem("subscription_plans"),
      filterValue: "Filter",
      followstatuscheck: "",
      tabPanelList: [
        {
          heading: "New Beats",
          value: "Beats"
        },
        {
          heading: "New Hooks",
          value: "Hooks"
        },
        {
          heading: "New Verses",
          value: "Verses"
        },
        {
          heading: "New Songs",
          value: "Songs"
        },
      ],
      notificationId:"",
      notificationUser:"",
      notificationType:""
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
   
   //new changes
   this.windowResized = this.windowResized.bind(this);
   this.updateWindowWidth = this.updateWindowWidth.bind(this);
   this.updateVideoWindowWidth = this.updateVideoWindowWidth.bind(this);
   this.checkCurrentTab = this.checkCurrentTab.bind(this)
   this.checkCurrentOriginalsTab = this.checkCurrentOriginalsTab.bind(this)

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }



  async componentDidMount() {
    super.componentDidMount();
    this.previousHref = window.location.href;
    this.handleUpdate()

    const loggedIn_userId = localStorage.getItem('loggedIn_userId')
    const profile_user_id = window.location.href.split('=')[1]
    if(profile_user_id != ('')){
      if(loggedIn_userId != profile_user_id){
        this.increaseProfileViewCount(profile_user_id)
      }
    }
  }


  increaseProfileViewCount = async (id: any) => {
    try {
      const response = await axios.post(
        `${configJSON.baseUrl}/bx_block_audiomusic/views/profile_view_count?id=${id}`,
        {}, // If you need to send data in the request body, provide it here
        {
          headers: {
            "Content-Type": "application/json",
            token: this.state.apiToken,
          },
        }
      );
  
      // Assuming the server responds with a message field
      console.log(response.data?.message);
    } catch (error) {
      console.error(error, "error in axios call ");
      // Handle the error gracefully, e.g., show an error message to the user
    }
  };
  

  getRegexFromURLParam = (paramName: any) => {
    if (paramName === "creative_id") {
      return /creative_id=\d+/;
    } else if (paramName === "listener_id") {
      return /listener_id=\d+/;
    } else {
      return /originals_id=\d+/;
    }
  }

  getParamName = (currentURL: any) => {
    if (currentURL.searchParams.get("creative_id")) {
      return "creative_id"
    } else if (currentURL.searchParams.get("listener_id")) {
      return "listener_id"
    } else {
      return "originals_id"
    }
  }

  componentDidUpdate(prevProps: any) {
    const currentHref: any = window.location.href;
    if (currentHref !== this.previousHref) {
      let currenturlArr = currentHref.split("/");
      let currentslug = currenturlArr[4].split('=')[1];

      let prevurlArr = this.previousHref.split("/");
      let prevslug = prevurlArr[4].split('=')[1];
      let currentURL = new URL(currentHref);


      const getParamNameData = this.getParamName(currentURL)
      const regex = this.getRegexFromURLParam(getParamNameData);
      const match = currentHref.match(regex);
      if (match) {
        if (currentslug !== prevslug) {
          this.handleUpdate()
          console.log("url changed")
          this.previousHref = currentHref;
        }
        // Perform your desired actions when the creative_id is present
      } else {
        window.location.replace(currentHref);
        this.previousHref = currentHref;
        // Perform your desired actions when the creative_id is not present
      }
    }

  }

  


  userTypeCheck = (creative: any, creativeId: any, url: any) => {
    if (creative && creative !== null) {
      this.setState({ creativeId: creative }, () => {
        creativeId = creative
        this.getCreativeProfileTrack()
        this.getTopVideos()

      })
    } else if (url.searchParams.get("listener_id") !== null) {
      this.setState({ creativeId: url.searchParams.get("listener_id") }, () => {
        creativeId = url.searchParams.get("listener_id")
        window.addEventListener("resize", this.windowResized);
        this.updateWindowWidth();
        this.updateVideoWindowWidth();
      })
    }

    else {
      creativeId = localStorage.getItem("user_id")
      this.setState({ creativeId: creativeId }, () => {
        this.getTopVideos()
        this.getCreativeProfileTrack()

      })

    }
    return creativeId
  }

  async handleUpdate() {
    this.changeScreenSize()
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creative =this.extractCreativeId(url);
    let creativeId;
    this.userTypeCheck(creative, creativeId, url)
    this.getToken();
    this.getUserProfileData()
    window.addEventListener("resize", this.windowResized);
    this.updateWindowWidth();
    this.updateVideoWindowWidth();

    let urlArr = window.location.href.split("/");

    let slug = urlArr.length !== 0 ? urlArr[4].substring(0, urlArr[4].indexOf('?')) : "";
    if (url.searchParams.get("listener_id")) {
      if (slug === "activity") {
        this.setState({ listenerValue: 0 })
        this.setState({ filterValue: 'Filter' })
        // this.getUploadTypeLists('Activity')
      }
      else {
        this.setState({ listenerValue: 1 })
        this.setState({ filterValue: 'Filter' })
        this.getPlayListList('Playlists')
      }
    }


    if (slug === "beats") {
      this.setState({
        value: 0
      });
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists('Beat')
    } else if (slug === "hooks") {
      this.setState({ value: 1 });
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists('Hook')
    } else if (slug === "verses") {
      this.setState({ value: 2 })
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists('Verse')
    }
    else if (slug === "songs") {
      this.setState({ value: 3 })
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists('Song')
    }
    else if (slug === "videos") {
      this.setState({ value: 4 })
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists('Video')
    } else if (slug === "activity") {
      this.setState({ value: 5 })
      this.setState({ filterValue: 'Filter' })
      // this.getUploadTypeLists('Activity')
    }
    else if (slug === "playlists") {
      this.setState({ value: 6 })
      this.setState({ filterValue: 'Filter' })
      this.getPlayListList('Playlists')
    }

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }
  handlePopupVisible = () => {
    if (this.state.unfollowState) {
      this.setState({ visible: true })
    } else {
      this.setState({ loadingFollow: true })
      this.FolloweUser(this.state.creativeById?.id)
    }
  }
  changeScreenSize = () => {
    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[4].substring(0, urlArr[4].indexOf('?')) : "";

    if (slug === "playlists") {
      this.setState(prevState => ({
        gridSizes: {
          ...prevState.gridSizes,
          mdSize: 8,
          lgSize: 9,
          xlSize: 4,
          xxlSize: 3,
        }
      }))
    } else {

      this.setState(prevState => ({
        gridSizes: {
          ...prevState.gridSizes,
          xsSize: 12,
          mdSize: 9,
          lgSize: 10,
          smSize: 2,
          // xlSize: 2
          xlSize: 2,
          xxlSize: 2
        }
      }))
    }
  }


  extractCreativeId(url: any) {
    if (url.searchParams.get("creative_id")) {
      return url.searchParams.get("creative_id");
    } else if (url.searchParams.get("listener_id")) {
      return url.searchParams.get("listener_id");
    } else if (url.searchParams.get("originals_id")) {
      return url.searchParams.get("originals_id");
    } else {
      return null; // Return a default value or handle the case where none of the parameters are found
    }
  }


  getUserProfileData = async () => {
    let url_string = window.location.href;

    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    let userId;

    if (creativeId !== null) {
      userId = creativeId;
    } else {
      userId = localStorage.getItem('user_id')

    }


      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userProfileData = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getuserprofiledata}id=${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
   
  }
  updateFilterValue = () => {
    this.setState({ filterValue: 'Filter' })
  }
  // Video song
  getNewReleaseVideo = async () => {

    this.setState({ tabLoading: true })
    this.setState({ getCreativeTabsLoader: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.newReleaseVideo = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getnewreleasevideo}user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  }


  getCreativeProfileTrack = async () => {
    this.setState({ tabLoading: true })

    let url_string = window.location.href;

    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    let userId;

    if (creativeId !== null) {
      userId = creativeId;
    } else {
      userId = localStorage.getItem('user_id')

    }

    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.creativeProfileTrack = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getcreativeprofiletrack}user_id=${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }


  }

  checkCurrentTab = (event: React.ChangeEvent<{}>, newValue: number) => {
      const types = ["Beat", "Hook", "Verse", "Song", "Video", "Activity", "Playlists"];
      const type = types[newValue] || "";
      
    if (newValue === 0) {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/beats?creative_id=${localStorage.getItem('user_id')}`)
      this.getUploadTypeLists(type)
    } else if (newValue === 1) {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/hooks?creative_id=${localStorage.getItem('user_id')}`)
      this.getUploadTypeLists(type)
    } else if (newValue === 2) {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/verses?creative_id=${localStorage.getItem('user_id')}`)
      this.getUploadTypeLists(type)
    } else if (newValue === 3) {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/songs?creative_id=${localStorage.getItem('user_id')}`)
      this.getUploadTypeLists(type)
    } else if (newValue === 4) {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/videos?creative_id=${localStorage.getItem('user_id')}`)
      this.getUploadTypeLists(type)
    } else if (newValue === 5) {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/activity?creative_id=${localStorage.getItem('user_id')}`)
      // this.getUploadTypeLists(type)
      this.getAllActivity();

      this.getNotificationData()
    } else if (type === 'Playlists') {
      this.setState({ filterValue: 'Filter' })
      HISTORY.push(`/creatives/playlists?creative_id=${localStorage.getItem('user_id')}`)
      this.setState({ playlistLoading: true })
      this.getPlayListList(type)
    }
    this.changeScreenSize()

    this.setState({ value: newValue });
  };

  checkCurrentOriginalsTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    const types = ["Beat", "Hook", "Verse", "Song", "Video", "Activity", "Playlists"];
    const type = types[newValue] || "";
    if (newValue === 0) {
      HISTORY.push(`/originals/beats?originals_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists(type)
    } else if (newValue === 1) {
      HISTORY.push(`/originals/hooks?originals_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists(type)
    } else if (newValue === 2) {
      HISTORY.push(`/originals/verses?originals_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists(type)
    } else if (newValue === 3) {
      HISTORY.push(`/originals/songs?originals_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists(type)
    } else if (newValue === 4) {
      HISTORY.push(`/originals/videos?originals_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists(type)
    } else if (newValue === 5) {
      HISTORY.push(`/originals/activity?originals_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getUploadTypeLists(type)
      this.getAllActivity();
      this.getNotificationData()
    } else if (type === 'Playlists') {
      HISTORY.push(`/originals/playlists?originals_id=${localStorage.getItem('user_id')}`)
        this.setState({ filterValue: 'Filter' })
      this.setState({ playlistLoading: true })
        this.getPlayListList(type)
    }
    this.changeScreenSize()
    this.setState({ value: newValue });
  };
  checkListenerCurrentTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    const type = newValue === 0 ? "Activity" : "Playlists";
    if (newValue === 0) {
      HISTORY.push(`/listeners/activity?listener_id=${localStorage.getItem('user_id')}`)
      this.setState({ filterValue: 'Filter' })
      this.getAllActivity();
      this.getNotificationData()
    } else if (type === 'Playlists') {
      HISTORY.push(`/listeners/playlists?listener_id=${localStorage.getItem('user_id')}`)
        this.setState({ filterValue: 'Filter' })
      this.setState({ playlistLoading: true })
        this.getPlayListList(type)
    }
    this.changeScreenSize()
    this.setState({ listenerValue: newValue })
  };

  handleClickOpen = () => {
    this.setState({ openAlertPopUp: true });
    let dialogText = "";

    if (this.state.token && this.state.userType !== "listener") {
      dialogText = "1";
    } else if (this.state.userType === "listener" && this.state.apiToken) {
      dialogText = "3";
    } else {
      dialogText = "2";
    }
    
    this.setState({ dialogText });

  };

  _readNotification = (notificationId: string,userType:any,notificationType:any,userId:any) => {
    this.setState({notificationId:userId,notificationUser:userType,notificationType:notificationType})
    let url = "/bx_block_push_notifications/push_notifications/update?id=" + notificationId

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotificationApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  calculateFilterValue = (value: any) => {
    let returnData: any;
    if (value == "today") {
      returnData = "Today"
    } else if (value == "this_week") {
      returnData = "This week"
    } else if (value == "this_month") {
      returnData = "This month"
    } else {
      returnData = "This Year"
    }
    return returnData
  }

  calculateFilterByType = (value: any) => {
    let returnData: any;
    if (value == "beats") {
      returnData = "Beat"
    } else if (value == "hooks") {
      returnData = "Hook"
    } else if (value == "songs") {
      returnData = "Song"
    } else if (value == "verses") {
      returnData = "verses"
    } else if (value == "videos") {
      returnData = "Video"
    } else if (value == "activity") {
      returnData = "Activity"
    } else {
      returnData = "Playlist"
    }
    return returnData
  }

  handleFilter = async (value: any) => {
    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[4].substring(0, urlArr[4].indexOf('?')) : "";
    let CurrentValue = this.calculateFilterValue(value)
    let currentFilter = this.calculateFilterByType(slug)
    this.setState({ filterValue: CurrentValue })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handleFilterApiCallId = requestMessage.messageId;
      this.setState({ tabLoading: true })
      if (currentFilter != "Activity") {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.creativeFilter}?user_id=${stateData}&upload_type=${currentFilter}&filter_value=${value}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `/bx_block_activityfeed/activity_tab?user_id=${stateData}&filter_value=${value}`
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
   
  }

  getAllActivity = async () => {
    this.setState({ getCreativeTabsLoader: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.allActivityData = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getallactivity}user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    

  }

  getFollowingData = async () => {
    this.setState({ loading: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.followingDataUser = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getfollowingdata}user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
   
  }


  getFollowerData = async () => {
    this.setState({ loading: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.followerDataUser = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getfollowerdata}user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
   

  }



  followUserByID = (id: any) => {

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.followUserById = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getfollowerUserById}user_id=${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
   
  }

  unfollowUserById = (id: any) => {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handleunfollowUserById = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getunfollowUserById}user_id=${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    

  }

  followUser = async () => {

    this.setState({ loading: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)
   
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handleFollowUser = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getfollowerUserById}?user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    


  }

  unFollowUser = async () => {
    this.setState({ loading: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handleUnfollowById = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getunfollowUserById}?user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
   
  }

  filterActivityData = (data: any) => {
console.log(data,"data in activity...")
    if (data.attributes?.reposts?.data) {
      this.setState({ get_All_Activity_data: [...data.attributes?.reposts?.data] })
    }

    if (data.attributes?.followings?.data) {
      this.setState({ get_All_Activity_data: [...this.state.get_All_Activity_data, ...data.attributes?.followings?.data] })
    }
    if (data.attributes?.uploads?.data) {
      let tempArray = Object.assign([], data.attributes?.uploads?.data);
      const key = 'id';
      const arrayUniqueByKey = [...new Map(tempArray.map((item: any) =>
        [item[key], item])).values()];
      this.setState({ get_All_Activity_data: [...this.state.get_All_Activity_data, ...arrayUniqueByKey] })
    }
    this.setState({ tabLoading: false })


    this.state.get_All_Activity_data.sort((a: any, b: any) => new Date(b?.attributes?.created_at).getTime() - new Date(a?.attributes?.created_at).getTime());
    this.setState({ getCreativeTabsLoader: false })

  }


  handleRedirectPlaylist = (id: any) => {
    const user_id: any = localStorage.getItem("user_id");
    
    if (this.state.creativeById.id !== user_id) {
      localStorage.setItem("playlist_type", "Public");
      this.setState({ tabLoading: true });
      history.push({
        pathname: `/home/playlist/public-view/${id.id}`,
        state: { creativeId: id.id }
      });
      window.location.reload();
    } else {
      localStorage.setItem("playlist_type", "Private");
      history.push({
        pathname: `/home/self-view/${id.id}`,
        state: { creativeId: id.id }
      });
      window.location.reload();
    }
  }
  


  handleTrackPlay = (data: any, index: any, songsList: any, name: any) => {
    let playPlaylist = false;
    let playlistData = data;
    let checkType = name;
  
    if (name === "Beats" || name === "Hooks" || name === "Verses") {
      if (this.state.apiToken) {
        playPlaylist = true;
        this.setState({ selectedPlayList: songsList });
      } else if (this.state.userType === "listener") {
        this.handleClickOpen();
      }
    } else if (name === "Songs" && this.state.apiToken) {
      playPlaylist = true;
    } else if (name === "Videos" && this.state.apiToken) {
      if (this.state.userType !== "listener") {
        playPlaylist = true;
      } else {
        this.handleClickOpen();
      }
    } else {
      playPlaylist = true;
      this.setState({ selectedPlayList: songsList });
    }
  
    this.setState({ playPlaylist, playlistData, checkType });
  }
  

  handleCardPlaylist = async (item: any) => {
     localStorage.setItem("creatives_screen", "false")
    const temp_id: any =  localStorage.getItem('temp_id')
    const user_id: any =  localStorage.getItem('user_id')
    const current_user = temp_id ? temp_id : user_id
    localStorage.setItem("currentPlaylistId", item?.id)
    if (this.state.apiToken && item?.attributes?.artist_id == current_user) {
      localStorage.setItem("playlist_type", "Private");
        this.setState({ tabLoading: true });
        history.push({
          pathname: `/home/self-view/${item?.id}`,
          state: { creativeId: item?.id }
        })
         localStorage.removeItem('temp_id')
    } else {
      localStorage.setItem("playlist_type", "Public");
        this.setState({ tabLoading: true });
        history.push({
          pathname: `/home/playlist/public-view/${item?.id}`,
          state: { creativeId: item?.id }
        })
         localStorage.removeItem('temp_id')
    }
  }

  handleClose = () => {
    this.setState({ openAlertPopUp: false });
  };
  handleCloseOpenUpgradModal = () => {
    this.setState({ OpenUpgradModal: false });
  }

  handleUpgradeModal = () => {
    this.setState({ openAlertPopUp: false });
    this.setState({ OpenUpgradModal: true });

  }

  handlePlayList = (data: any, index: number, arr: [], id: any, type: any, name: any) => {
    let playPlaylist = false;
    let playlistData = data;
    let checkType = name;
    let selectedPlayList: any[] = []; 

    if ((name === "Beats" || name === "Hooks" || name === "Verses") && this.state.apiToken) {
      playPlaylist = true;
      checkType = name;
      selectedPlayList = arr;
    } else if (name === "Songs" && this.state.apiToken) {
      playPlaylist = true;
      playlistData = data;
      checkType = name;
    } else if (name === "Videos" && this.state.apiToken) {
      if (this.state.userType !== "listener" || this.state.userType === "listener") {
        playPlaylist = true;
        playlistData = data;
        checkType = name;
      } else {
        this.handleClickOpen();
      }
    } else {
      playPlaylist = true;
      playlistData = data;
      checkType = name;
      selectedPlayList = arr;
    }
  
    this.setState({ playPlaylist, playlistData, checkType, selectedPlayList });
  }

  
  
  getList = (type: string) => {
    this.setState({ tabLoading: true })

    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getDataByType = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.alllistEndpoint}?upload_type=${type}&user_type=public&user_id=${stateData}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  getPlayListList = async (type: string) => {
    this.setState({ getCreativeTabsLoader: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

    try {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handlePlaylistData = requestMessage.messageId;
      if (type) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.publicPlaylistEndpoint}/user_playlist?id=${stateData}`
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    } finally {

      this.getPlayListTop100Lists(type)
      this.setState({ getCreativeTabsLoader: false })
    }

  }
  getPlayListTop100Lists = async (type: string) => {
    this.setState({ tabLoading: true })

    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.gettop100PlaylistsUser = requestMessage.messageId;
      if (type) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.getTop100Playlist}user_id=${stateData}`
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  

  }


  getUploadTypeLists = async (type: any) => {
    this.setState({ getCreativeTabsLoader: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)


    try {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllUploadTypePlaylist = requestMessage.messageId;
      if (type) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.alllistEndpoint}?upload_type=${type}&user_type=public&user_id=${stateData}`
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    } finally {

      this.getTop100UploadTypeLists(type)
      this.setState({ getCreativeTabsLoader: false })
    }

  }

  getNotificationData = async () => {
    // this.setState({ getCreativeTabsLoader: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAllNotification = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.getNotificationData}user_id=${stateData}`
      );


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

  }

  getTop100UploadTypeLists = async (type: any) => {
    this.setState({
      tabLoading: true,
      getTop100: []
    })

    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getTop100UploadedType = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.gettop100UploadList}user_id=${stateData}&upload_type=${type}`
      );


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    

  }

  getTopProfileVideos = async (type: any) => {
    this.setState({ tabLoading: true })

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getTopProfileVideoData = requestMessage.messageId;
      if (type) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.gettopProfileVideos}`
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

   updateWindowWidth() {
        const windowWidth = window.innerWidth;
        let columns;

        if (windowWidth <= 1560 && windowWidth >= 1393) {
            columns = 6;
        } else if (windowWidth <= 1393 && windowWidth >= 1088) {
            columns = 5;
        } else if (windowWidth <= 1088 && windowWidth >= 800) {
            columns = 4;
        } else if (windowWidth <= 800 && windowWidth >= 540) {
            columns = 3;
        } else if (windowWidth <= 540 && windowWidth >= 380) {
            columns = 2;
        } else if (windowWidth <= 380) {
            columns = 1;
        }

        this.setState({
            window_width: columns,
        });
    }

    updateVideoWindowWidth() {
      const windowWidth = window.innerWidth;
      let columns;

      if (windowWidth >= 1560) {
        columns = 4;
      }
      else if (windowWidth <= 1560 && windowWidth >= 1393) {
          columns = 4;
      } else if (windowWidth <= 1393 && windowWidth >= 1088) {
          columns = 3;
      } else if (windowWidth <= 1088 && windowWidth >= 800) {
          columns = 3;
      } else if (windowWidth <= 800 && windowWidth >= 540) {
          columns = 3;
      } else if (windowWidth <= 540 && windowWidth >= 380) {
          columns = 2;
      } else if (windowWidth <= 380) {
          columns = 1;
      }

      this.setState({
          video_window_width: columns,
      });
  }

  windowResized() {
    let _this = this;
    setTimeout(function () {
      _this.updateWindowWidth();
      _this.updateVideoWindowWidth();
    }, 500);
  }
  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }


  getDashboardData(): boolean {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCreativeId = (id: any) => {
    if ((this.state.creativeId !== undefined) && (this.state.creativeId !== "")) {
      return this.state.creativeId
    } else if (id !== "") {
      return id
    } else {
      return localStorage.getItem("user_id")
    }
  }
  handleRedirectToMessage = () => {
    const stateData = this.state.creativeId !== "" ? this.state.creativeId : localStorage.getItem("user_id");
    HISTORY.push({
      pathname: "/see-all-messages",
      state: { user_id: stateData }
    })
    window.location.reload()

  }



  getTop100Lists = (type: string) => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)

 
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getTop100List = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.alllistTopSongs}?upload_type=${type}&user_type=public&user_id=${stateData}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getTopVideos = () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopVideoList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.topVideos}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getPlayList = () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPlayListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.playListsEndPoint}`
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getFollowerList = () => {
    this.setState({ tabLoading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowerListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.profileNedpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  FolloweUser = (userId: any) => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.followUserUrl}` + userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  UnFolloweUser = (userId: any) => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUnFollowId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.unfollowUserUrl}` + userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getUserFollowerList = () => {

    const stateData = this.state.creativeId !== "" ? this.state.creativeId : localStorage.getItem("user_id")

    if (!stateData) {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserFollowerListId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `${configJSON.followerLists}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }
  // creative get data by ID
  getCreativeById = () => {
    this.setState({ crativeIdtabLoading: true })
    let url_string = window.location.href;
    let url = new URL(url_string);
    let creativeId = this.extractCreativeId(url);
    const stateData = this.handleCreativeId(creativeId)
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCreativeListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.creativesById}?id=${stateData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }



  //end
  getActivityList = () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getactivityListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.activityList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getTopPlaylist = () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getallPlaylistId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.allPlaylistEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  setDataBasedOnTabSuccessCall = (responseJson: any, errorReponse: any) => {
    if (responseJson.message) {
      this.setState({ beatList: "" });
      this.setState({ beatErrorResponse: responseJson.message });
      this.setState({ tabLoading: false })
    }
    else if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ beatList: "" });
      this.setState({ beatList: responseJson.data });
      this.setState({ tabLoading: false })

    } else {
      this.parseApiCatchErrorResponse(errorReponse);
      const errors = responseJson?.errors;
      this.setState({ beatErrorResponse: responseJson?.errors })
      if (errors?.name?.[0]) {
        console.log("errorReponse in candidate fetch", errors.name[0]);
      }
    }
  }

  setFollowerListData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ followerData: responseJson.data }, () => {
        this.setState({ tabLoading: false })
      })
    } else {
      this.setState({ followerData: responseJson.errors || [] })
    }
  }

  
  setDataFromFilterApi = (responseJson: any) => {
    if (responseJson !== undefined && responseJson.data) {
      if (responseJson.data[0].type == "playlist") {
        this.setState({
          getTopPlaylists: responseJson.data.filter((item: any) =>
            item?.attributes.play_list_type !== "Video"),
          top_playlistVideo: responseJson.data.filter((item: any) =>
            item?.attributes.play_list_type === "Video")
        })
      } else {
        this.setState({
          getTop100: responseJson.data
        })
      }

    } else if (responseJson.type == "activity_tab") {
      this.filterActivityData(responseJson)
    } else if (responseJson.message) {
      this.setState({ getTop100: [], loading: false, get_All_Activity_data: [], getTopPlaylists: [], top_playlistVideo: [] })
      openNotification(responseJson?.message, "Success")
    }
    else {
      this.setState({ loading: false });
    }
  }

  setCreativeListById = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ creativeById: responseJson.data, unfollowState: responseJson?.data?.attributes?.follow_status }, () => {
        this.setState({ crativeIdtabLoading: false })
      })
    }
  }

  setFollowIdHandler = (responseJson: any, value: boolean) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson?.message, "Success")
      this.getCreativeById();
      this.setState({ loadingFollow: false, unfollowState: value })
    }
  }

  setTop100ListData = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({ beatList: "" });
      this.setState({ beatErrorResponse: responseJson.message });
      // this.setState({ loading: false })
    }
    else if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ beatErrorResponse: "" });
      this.setState({
        getTop100: responseJson.data,
        //  loading: false
      })
    } else {
      this.setState({ handletokenError: true })
    }
  }

  handleTop100List = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getTop100List) {
      this.setTop100ListData(responseJson)
    } else if (apiRequestCallId === this.getallPlaylistId) {
      if (responseJson !== undefined && !responseJson.errors) {
        console.log("async function")
      }
    } else if (apiRequestCallId === this.getUnFollowId) {
      this.setFollowIdHandler(responseJson, false)
    } else if (apiRequestCallId === this.getCreativeListId) {
      this.setCreativeListById(responseJson)
    } else if (apiRequestCallId === this.getFollowId) {
      this.setFollowIdHandler(responseJson, true)

    } else {
      return
    }

  }


  handleUserFollowerList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ userFollowerListData: responseJson.data });
    }
  };

  handleTopVideoData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ songsList: responseJson.data });
    }
  }

  handleProfileData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        unfollowState: responseJson.data.attributes.follow_status,
        followstatuscheck: responseJson.data.attributes.follow_status ? "Unfollow" : "follow",
        creativeById: responseJson.data,
      }, () => {
        this.getFollowerList()
        this.getUserFollowerList()
        this.getPlayList()
        this.getAllActivity();
        this.getNotificationData();
        this.getNewReleaseVideo()
        this.getCreativeById()

      })
    }
  }

  handleReleaseVideoList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        new_released_video: responseJson?.data?.attributes,
        new_released_video_attributes: responseJson?.data,
        tabLoading: false
      })
    } else {
      this.setState({ tabLoading: false })
    }
  }

  handleCreativeProfileList = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      let arr = [{
        title: "Plays",
        count: responseJson.total_play_count,
        percentage: responseJson.play_count_percentage
      },
      {
        title: "Views",
        count: responseJson.total_view_count,
        percentage: responseJson.view_count_percentage
      },
      {
        title: "Reposts",
        count: responseJson.total_repost_count,
        percentage: responseJson.repost_count_percentage
      }
      ]
      this.setState({ profileCountData: arr })
      this.setState({ tabLoading: false })

    } else {
      this.setState({ tabLoading: false })
    }
  }

  handleActivityData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      responseJson?.data?.attributes ?

        this.setState({ activityList: responseJson?.attributes })
        :
        this.setState({
          get_All_Activity_data: [],
          activityList: [],
          beatErrorResponse: responseJson.message,
          tabLoading: false
        })
      responseJson?.attributes && this.filterActivityData(responseJson)


    } else {
      this.setState({
        get_All_Activity_data: [],
        activityList: [],
        // beatErrorResponse: responseJson.errors.message,
        beatErrorResponse: responseJson.errors,

        getCreativeTabsLoader: false
      })

    }
  }

  handleUserFollowerListData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        followingData: responseJson?.data,
        loading: false
      })


    } else {
      this.setState({
        loading: false
      })
    }
  }

  handleFollowerUserData = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        allFollowerData: responseJson?.data,
        loading: false
      })


    } else {
      this.setState({ loading: false })
    }
  }

  handleUnfollowerById = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({ loading: false })
      this.getFollowerData()
      this.getFollowingData()
      this.getUserProfileData()


    } else {
      this.setState({ loading: false })
    }
  }

  handleFollowUserId = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if(responseJson.message){
        this.setState({
          openPopupFollowers:false,
          openPopupFollowing:false
        })
        openNotification(responseJson.message,"Error")
      }else{
      this.setState({ loading: false })
      this.getFollowerData()
      this.getFollowingData()
      this.getUserProfileData()

      }
    } else {
      this.setState({ loading: false })
    }
  }

  handleGetPlaylistData = (responseJson: any) => {
    
      if (responseJson !== undefined && !responseJson.errors && !responseJson.message) {
        
        this.setState({
          beatList: responseJson.data.filter((item: any) =>
            item?.attributes.play_list_type != "video"
          ),
          playlistVideo: responseJson.data.filter((item: any) =>
            item?.attributes.play_list_type === "video"
          ),
          getCreativeTabsLoader: false
        })

      } else {
        
        this.setState({ beatList: [] })
        this.setState({ getCreativeTabsLoader: false })
    }
  }

  handleTop100PlaylistUser = (responseJson: any) => {
    

    if (responseJson !== undefined && !responseJson.errors&&  !responseJson.message) {
    

      this.setState({
        getTopPlaylists: responseJson.data.filter((item: any) =>
          item?.attributes.play_list_type !== "video"),

        top_playlistVideo: responseJson.data.filter((item: any) =>
          item?.attributes.play_list_type === "video"
        ),

        tabLoading: false
      })
    this.setState({ playlistLoading: false })



    } else {
      this.setState({ tabLoading: false,playlistLoading: false  })
    }
  }

  handleGetAllUploadedPlaylist = (responseJson: any) => {
    
    if (responseJson !== undefined && !responseJson.errors) {
      responseJson?.tracks?.data ?
        this.setState({ beatList: responseJson?.tracks?.data })
        :
        this.setState({ beatErrorResponse: responseJson.message, beatList: [] });


    } else {
      this.setState({ tabLoading: false })
    }

  }

  handleGetTop100UploadedType = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      responseJson?.data ?
        this.setState({ getTop100: responseJson?.data })
        :
        this.setState({
          beatErrorResponse: responseJson.message, getTop100: [],
          tabLoading: false
        })
    } else {
      this.setState({ tabLoading: false })
    }

  }

   redirectHandle = async () => {
    localStorage.removeItem("selectedTab");
   const id: any = localStorage.getItem("user_id");
    localStorage.setItem("temp_id", id);
    localStorage.removeItem("user_id");
    localStorage.setItem("user_id", this.state.notificationId);
    localStorage.setItem("creatives_screen", "true");
   // await localStorage.removeItem("current_type")
   const user = this.state.notificationUser;
    localStorage.setItem("current_type", user);
     HISTORY.push({
       pathname:
       this.state.notificationUser === "listener"
           ? `/listeners/activity?listener_id=${this.state.notificationId}`
           : `/creatives/beats?creative_id=${this.state.notificationId}`,
       // state: { creativeId: props?.id }
     });
    
   
 };


  handleGetAllNotification = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors && !responseJson.message) {
      if (responseJson.data) {
        this.setState({
          get_notification: responseJson.data
        })
        
      } else {
        this.setState({
          get_notification: {},
          getCreativeTabsLoader: false
        })
      }
    } 
  }

  handleReadNotification=(responseJson:any)=>{
    if (responseJson !== undefined && !responseJson.errors && this.state.notificationId && this.state.notificationType !=="followers" ) {

    this.redirectHandle()
    }else if (this.state.notificationType ==="followers"||this.state.notificationId === ""|| this.state.notificationId === null){
        this.getNotificationData();
       }else{
      console.log(responseJson.errors,"notification read msg")
    }
  }


  handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getUserFollowerListId:
        this.handleUserFollowerList(responseJson);
        break;

      case this.handleFilterApiCallId:
        this.setDataFromFilterApi(responseJson);
        break;

      case this.getTopVideoList:
        this.handleTopVideoData(responseJson)
        break;
      // new changes....
      case this.userProfileData:
        this.handleProfileData(responseJson)
        break;
      case this.newReleaseVideo:
        this.handleReleaseVideoList(responseJson)
        break;

      case this.creativeProfileTrack:
        this.handleCreativeProfileList(responseJson)

        break;
      case this.allActivityData:
        this.handleActivityData(responseJson)
        break;
      case this.followingDataUser:
        this.handleUserFollowerListData(responseJson)
        break;
      case this.followerDataUser:
        this.handleFollowerUserData(responseJson)
        break;
      case this.handleunfollowUserById:

        this.handleUnfollowerById(responseJson)
        break;
      case this.followUserById:
        this.handleFollowUserId(responseJson)

        break;
      case this.handleFollowUser:
        if (responseJson !== undefined && !responseJson.errors) {
          this.setState({ loading: false })
          this.getFollowerData()


        } else {
          this.setState({ loading: false })
        }

        break;
      case this.handlePlaylistData:
        this.handleGetPlaylistData(responseJson)

        break;
      case this.handleUnfollowById:
        if (responseJson !== undefined && !responseJson.errors) {
          this.setState({ loading: false })

        }

        break;
      case this.gettop100PlaylistsUser:
        this.handleTop100PlaylistUser(responseJson)
        break;
      case this.getAllUploadTypePlaylist:
        this.handleGetAllUploadedPlaylist(responseJson)
        break;
      case this.getTop100UploadedType:
        this.handleGetTop100UploadedType(responseJson)
        break;
      case this.getAllNotification:
        this.handleGetAllNotification(responseJson)
        break;
        case this.getAllNotificationApiId:
          this.handleReadNotification(responseJson)
          break;
      case this.getTopProfileVideoData:
        if (responseJson !== undefined && !responseJson.errors) {
          this.setState({
            tabLoading: false,
            getTopVideos: responseJson?.data?.data
          })
        } else {
          this.setState({ tabLoading: false })
        }
        break;
      default:
        // Handle any other cases if needed
        break;
    }
  };
 





  async receive(from: String, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

   
    //new changes
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId == null) {
      this.parseApiCatchErrorResponse(errorReponse);
      return;
    }

    switch (apiRequestCallId) {
      case this.getDataByType:
        this.setDataBasedOnTabSuccessCall(responseJson, errorReponse);
        break;
      case this.getPlayListId:
        if (responseJson && !responseJson.errors) {
          this.setState({ playList: responseJson.data });
        }
        break;
      case this.getFollowerListId:
        this.setFollowerListData(responseJson);
        break;
      default:
        break;
    }

    this.handleRestApiCall(apiRequestCallId, responseJson)
    this.handleTop100List(apiRequestCallId, responseJson)

  }

  handleValidationApiResponse(responseJson: any) {
  this.arrayholder = responseJson.data;
  if (this.arrayholder && this.arrayholder.length !== 0) {
    const regexData = this.arrayholder[0];
    if (regexData) {
      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }
      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
      if (regexData.email_validation_regexp) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }
    }
  }
}

handleUserProfileResponse(responseJson: any, errorReponse: any) {
  if (responseJson && !responseJson.errors && responseJson.data && responseJson.data.attributes) {
    this.handleUserProfileSuccess(responseJson.data.attributes);
  } else {
    this.handleUserProfileError(responseJson, errorReponse);
  }
}

handleUserProfileSuccess(userAttr: any) {
  const {
    email,
    first_name,
    last_name,
    country_code,
    phone_number,
    type,
  } = userAttr;

  this.userAttr = userAttr;

  this.setState({
    email,
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number ? phone_number : "",
  });
              //@ts-ignore

  this.txtInputFirstNameProps.value = first_name;
              //@ts-ignore

  this.txtInputLastNameProps.value = last_name;
  this.txtInputEmailProps.value = email;
              //@ts-ignore
  
  this.txtInputPhoneNumberProps.value = phone_number;

  this.registrationAndLoginType = type;

  if (country_code) {
    this.setState({ currentCountryCode: country_code });
  }

}

handleUserProfileError(responseJson: any, errorReponse: any) {
  if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token) {
    this.showAlert("Session Expired", "Please Log in again.");
  } else {
    this.parseApiErrorResponse(responseJson);
  }

  this.parseApiCatchErrorResponse(errorReponse);
}

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }
  
}
// Customizable Area End