// Customizable Area Start
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import VideosControls from "./VideosControls.web";
import screenfull from "screenfull";
import { makeStyles } from "@material-ui/core/styles";
import '../../../blocks/dashboard/src/Charts/Videos/MessageSection.web.css'
import { AudioContextApi } from "../../../blocks/dashboard/src/AudioContext/AudioContext.web";
import { openNotification } from "../Notification.web";
import { album1 } from "../../../blocks/dashboard/src/assets";



const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    width: "100%",
height:"100%",
    position: "relative",
    // "&:hover": {
    //   "& $controlsWrapper": {
    //     visibility: "visible",
    //   },
    // },
  },

  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topControls: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  middleControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "column",

    // background: "rgba(0,0,0,0.6)",
    // height: 60,
    padding: theme.spacing(2),
  },

  bottomControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // height:40,
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },

  volumeSlider: {
    width: 100,
  },
}));


const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date
    .getUTCSeconds()
    .toString()
    .padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

let count = 0;

export const VideosPlayer = ({ topvideo, trackId, setVideoCount, playVideoFromRedirection, setPlayVideoFromRedirection, currentTrack, setTopVideoObj, setSetCurrentKey, setTrackId, setUserTrack, setRepostCount, getFilterTrack, artWork}: any) => {
  const [playerstate, setPlayerState] = useState({
    pip: false,
    playing: false,
    controls: false,
    light: false,
    muted: false,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
  });
  const {
    playing,
    light,
    muted,
    loop,
    playbackRate,
    pip,
    played,
    volume,
  } = playerstate;
  const classes = useStyles();

  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("remaining");
  const [bookmarks, setBookmarks] = useState([]);
  const playerRef = useRef<any>(null);
  const playerContainerRef = useRef<any>(null);
  const controlsRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const {handleAudioVideo, setHandleAudioVideo} = useContext(AudioContextApi);
  
  useEffect(() => {
    controlsRef.current.style.visibility = "visible";
    count = 0;
    if(playVideoFromRedirection){
      setPlayerState({ ...playerstate, playing: true})
    }

    if(handleAudioVideo == 2 ){
      if(playerstate.playing || playVideoFromRedirection){
        setHandleAudioVideo(1)
      } 
    }
  }, [topvideo, pip,handleAudioVideo]);



  useEffect(() => {
    playCount();
  })

  const playCount = () => {
    if (playerRef) {
      if (playerRef.current){
        if (playing){
        if (played != 0){
          if (playerRef.current.getDuration() === playerRef.current.getCurrentTime()) {
            setPlayerState({ ...playerstate, playing: !playing })
            setVideoCount(trackId);
            sendPlayingVideo(trackId)
          }
        }
      }
    }
    }
  }

  const setNextVideoSongsData = (item: any, index: any) => {
    setPlayVideoFromRedirection(true)
    setTopVideoObj(item.attributes)
    setTrackId(item.id)
    setUserTrack(item.attributes.user_tracks?.data)
    setSetCurrentKey(index < 9 ? "0" + Number(index + 2) : index + 2)
    setRepostCount(item.attributes.repost_count)
    getFilterTrack(item?.id)
    if(index > 0){
    localStorage.setItem("selectedSingleVideo", JSON.stringify({ ...item, currentKey: index < 9 ? "0" + Number(index + 1) : index + 1 }));
    }else{
      localStorage.setItem("selectedSingleVideo", JSON.stringify({ ...item, currentKey: "01"}));
    }
}

  const sendPlayingVideo = (playingVideoId: any) =>{
    const index = currentTrack.findIndex((video: any) => video.id === playingVideoId);
    if(index == -1){
      setNextVideoSongsData(currentTrack[0],index)
    }else if(index >= 0 && index != currentTrack.length - 1){
      setNextVideoSongsData(currentTrack[ index + 1], index)
    }else if(index == currentTrack.length-1){
      setvideoPlayOneByOne()
    }
    else{
      setvideoPlayOneByOne()
    }
 }

 const setvideoPlayOneByOne = () =>{
    setPlayVideoFromRedirection(false)
    return null
 }

  const handlePlayPause = () => {
    controlsRef.current.style.visibility = "visible";
    count = 0;
    if(handleAudioVideo == 2 ){
      setHandleAudioVideo(1)
    }else{
      setPlayerState({ ...playerstate, playing: !playing });
      setPlayVideoFromRedirection(false)
    }
  };

  const playOrPauseVideo = () =>{
    controlsRef.current.style.visibility = "visible";
    count = 0;
    if(handleAudioVideo == 2 ){
      setPlayerState({ ...playerstate, playing: false})
      setPlayVideoFromRedirection(false)
    }else{
      setPlayerState({ ...playerstate, playing: !playing });
      setPlayVideoFromRedirection(false)
    }
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleProgress = (changeState: any) => {
    if (count > 3) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    if (controlsRef.current.style.visibility == "visible") {
      count += 1;
    }
    if (!playerstate.seeking) {
      setPlayerState({ ...playerstate, ...changeState });
    }
  };

  const handleSeekChange = (e: any, newValue: any) => {
    setPlayerState({ ...playerstate, played: parseFloat(String(newValue / 100)) });
  };

  const handleSeekMouseDown = (e: any) => {
    setPlayerState({ ...playerstate, seeking: true });
  };

  const handleSeekMouseUp = (e: any, newValue: any) => {
    setPlayerState({ ...playerstate, seeking: false });
    playerRef.current.seekTo(newValue / 100, "fraction");
  };

  const handleDuration = (duration: any) => {
    setPlayerState({ ...playerstate, duration });
  };

  const handleVolumeSeekDown = (e: any, newValue: any) => {
    setPlayerState({
      ...playerstate,
      seeking: false,
      volume: parseFloat(String(newValue / 100)),
    });
  };
  const handleVolumeChange = (e: any, newValue: any) => {
    setPlayerState({
      ...playerstate,
      volume: parseFloat(String(newValue / 100)),
      muted: newValue === 0 ? true : false,
    });
  };

  const toggleFullScreen = () => {
      screenfull.toggle(playerContainerRef.current);
      setShowFullScreen(!screenfull.isFullscreen);
   
  };

  const handleMouseMove = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      handleMouseMoveMobile();
    }else{
      controlsRef.current.style.visibility = "visible";
      count = 0;
    }
  };

  const handleMouseMoveMobile = () => {
    const controlsElement = controlsRef.current;
  
    controlsElement.style.visibility = "visible";
  
    count = 0;
   
     setTimeout(() => {
      controlsElement.style.visibility = "hidden";
    }, 3000);
  };

  const hanldeMouseLeave = () => {
    controlsRef.current.style.visibility = "hidden";
    count = 0;
  };

  const handleDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat == "remaining" ? "normal" : "remaining"
    );
  };

  const handlePlaybackRate = (rate: any) => {
    setPlayerState({ ...playerstate, playbackRate: rate });
  };

  const hanldeMute = () => {
    setPlayerState({ ...playerstate, muted: !playerstate.muted });
  };

  const addBookmark = () => {
    const canvas = canvasRef.current;
    canvas.width = 160;
    canvas.height = 90;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      playerRef.current.getInternalPlayer(),
      0,
      0,
      canvas.width,
      canvas.height
    );

    canvas.width = 0;
    canvas.height = 0;
    const bookmarksCopy = [...bookmarks];
    bookmarksCopy.push();
    setBookmarks(bookmarksCopy);
  };

  const handlePIPMode = () => {
    setPlayerState({ ...playerstate, pip: !pip });
  };

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";

  let elapsedTime =
    timeDisplayFormat == "remaining"
      ? `-${format(duration - currentTime)}`
      : format(currentTime);

  elapsedTime = elapsedTime == "-0:00" ? "0:00" : elapsedTime;

  const totalDuration = format(duration);

  /*video play count*/

const getStartFun = () =>{
  if(playerRef.current && playerRef.current.getCurrentTime() == 0){
    return true
  }else{
    return false
  }
}

  return (
    <>
      <div style={{height:"100%"}}>
        <div
          onMouseMove={handleMouseMove}
          onMouseLeave={hanldeMouseLeave}
          ref={playerContainerRef}
          className={classes.playerWrapper}
          style={{position: 'relative'}}
        >
          <div onClick={() =>handlePlayPause()} >
            {
             !playerstate.playing && getStartFun() &&     
               <div style={{position: 'absolute', width: '100%',height: '100%'}}>
                 <img style={{ width: '100%',height: '100%', borderBottom: '10px solid black'}} src={artWork} />
               </div>
            }
          <ReactPlayer
            id="pip_video_player"
            ref={playerRef}
            width="100%"
            height="100%"
            url={topvideo}
            pip={pip}
            stopOnUnmount={false}
            playing={playing}
            controls={false}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume || 0.5}
            muted={muted}
            onProgress={handleProgress}
            config={{
              file: {
                attributes: {
                  crossorigin: "anonymous",
                },
              },
            }}
            
          />
          </div>
          <VideosControls
            ref={controlsRef}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            onDuration={handleDuration}
            onRewind={handleRewind}
            onPlayPause={handlePlayPause}
            onFastForward={handleFastForward}
            playing={playing}
            played={played}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            onMute={hanldeMute}
            muted={muted}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekDown={handleVolumeSeekDown}
            onChangeDispayFormat={handleDisplayFormat}
            playbackRate={playbackRate}
            onPlaybackRateChange={handlePlaybackRate}
            onToggleFullScreen={toggleFullScreen}
            volume={volume}
            onBookmark={addBookmark}
            showFullScreen={showFullScreen}
            onPipMode={handlePIPMode}
            playVideoFromRedirection={playVideoFromRedirection}
            topVideo = {topvideo}
          />
        </div>
      </div>
    </>
  );
};
// Customizable Area End